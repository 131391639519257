import React from "react";
import { isDesktop } from "react-device-detect";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Flex,
  InputLeftElement,
  Text,
  Tag,
  InputGroup,
  Input,
  Spacer,
  Box,
  FlexProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { BiSearchAlt2 } from "react-icons/bi";

import { useFuseSearch } from "../../../hooks/useFuseSearch";
import { SelectorSearch } from "../../molecules/selector/SelectorSearch";
import { ImageWithMissingSrc } from "../ImageWithMissingSrc";

import { returnBorderColor } from "./returnBorderColor";

interface IOptionContainer {
  options: SelectOptionType[];
  selectedOptions: string[];
  doubleRows: boolean;
  searchEnable: boolean;
  description: boolean;
  exclusive: boolean;
  /* Determines the height of the options container based on the options to view at one time without scrolling */
  maxOptionsInView?: number;
  /* Total max options to show without searching. Useful for a large list of options that is slow to render */
  maxOptionsToDisplay?: number;
  searchValue: string;
  onClose: () => void;
  onClick: (selection: string) => void;
  onChangeSearchValue: (value: string) => void;
  optionHeight: number;
  optionContainerProps?: FlexProps;
  showIcon?: boolean;
}

// little helper function to calculate the height of the optionsContainer
const calculateMaxHeight = (
  searchEnable: boolean,
  doubleRows: boolean,
  numberOfMaxOptions: number | undefined,
  optionsLength: number,
  optionHeight: number
) => {
  const totalRows = doubleRows ? optionsLength / 2 : optionsLength;
  if (numberOfMaxOptions && totalRows > numberOfMaxOptions) {
    if (searchEnable) {
      return `${optionHeight * numberOfMaxOptions + 50}px`;
    }
    return `${optionHeight * numberOfMaxOptions}px`;
  }
  return "auto";
};

export const OptionsContainer = ({
  options,
  selectedOptions,
  doubleRows,
  searchEnable,
  description,
  exclusive,
  maxOptionsInView,
  maxOptionsToDisplay,
  searchValue,
  onClose,
  onClick,
  onChangeSearchValue,
  optionHeight,
  optionContainerProps,
  showIcon,
}: IOptionContainer): JSX.Element => {
  let searchFilteredOptions: SelectOptionType[] = useFuseSearch(
    options ?? [],
    ["description", "label"],
    searchValue
  );

  if (maxOptionsToDisplay) {
    searchFilteredOptions = searchFilteredOptions.slice(0, maxOptionsToDisplay);
  }

  return (
    <Container
      maxH={calculateMaxHeight(
        searchEnable,
        doubleRows,
        maxOptionsInView,
        options.length,
        optionHeight
      )}
      zIndex={1}
      optionContainerProps={optionContainerProps}
    >
      {searchEnable && (
        <SelectorSearch
          searchValue={searchValue}
          setSearchValue={onChangeSearchValue}
        />
      )}
      {searchFilteredOptions.map((option, index) => (
        <Flex
          key={option.value}
          h={`${optionHeight}px`}
          w={doubleRows ? "50%" : "100%"}
          p="10px 10px"
          cursor="pointer"
          alignItems="center"
          borderColor={returnBorderColor(index, options.length, doubleRows)}
          borderWidth="1px"
          bgColor="white.0"
          overflow="hidden"
          _hover={{ bgColor: "white.500", borderColor: "white.500" }}
          _focus={{ borderColor: "#000" }}
          onClick={() => {
            onClick(option.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onClick(option.value);
            }
          }}
          tabIndex={0}
          onKeyDown={() => {
            if (index === options.length - 1) onClose();
          }}
        >
          {/* -------------- Icon ---------------- */}
          {(showIcon ?? true) && (
            <ImageWithMissingSrc src={option.icon} alt="" h="30px" mr="10px" />
          )}
          {/* -------------- Name ---------------- */}
          <Flex direction="column">
            <Text
              fontSize="0.875rem"
              color="black.700"
              whiteSpace="nowrap"
              mr="10px"
            >
              {option.label}
              {exclusive && index === 0 && (
                <Tag
                  ml="10px"
                  size="sm"
                  color="red.500"
                  bgColor="red.100"
                  fontSize="0.625rem"
                >
                  Exclusive
                </Tag>
              )}
            </Text>
            {/* -------------- Description ---------------- */}
            {description && (
              <Text fontSize="0.75rem" color="black.550">
                {exclusive && index === 0 && (
                  <Text as="span" fontSize="0.75rem" color="red.500" mr="3px">
                    Syla
                  </Text>
                )}
                {option.description}
              </Text>
            )}
          </Flex>
          <Spacer />
          {selectedOptions.includes(option.value) ? (
            <CheckIcon w={3.5} color="#ff3600" />
          ) : (
            <Box w="3px" />
          )}
        </Flex>
      ))}
    </Container>
  );
};

const Container = styled(Flex, {
  shouldForwardProp: (prop) =>
    !["optionContainerProps"].includes(prop as string),
})((props) => ({
  width: "auto",
  maxHeight: props.maxH,
  overflowY: "auto",
  flexWrap: "wrap",
  marginTop: "10px",
  backgroundColor: "#fff",
  borderWidth: "1px",
  borderColor: "#f5f5f5",
  borderRadius: "5px",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  transform: props.transform,
  position: "absolute",
  top: "50px",
  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.08)",
  ...props.optionContainerProps,
}));
