import { parseDecimal, parseDate } from "@syla/shared/types/helpers/parsing";
import { ValueAssetsRequestBody } from "@syla/shared/types/requests/ValueAssetsRequest";
import { ValueAssetsResponseBody } from "@syla/shared/types/responses/ValueAssetsResponse";
import axios from "axios";
import { TransportType } from "@syla/shared/types/helpers/TransportType";

export type ValueAssetsResponse = ValueAssetsResponseBody<string>;

export const valueAssets = async ({
  date,
  baseAsset,
  assets,
}: {
  date?: Date;
  baseAsset: { _id: string };
  assets: { _id: string }[];
}): Promise<ValueAssetsResponse> => {
  const body: ValueAssetsRequestBody = {
    baseAsset,
    assets,
    date: date?.toISOString(),
  };

  const { data }: { data: TransportType<ValueAssetsResponse> } =
    await axios.post(`/assets/value-assets`, body);

  // convert api returned Decimal string to Decimal
  return {
    ...data,
    valueDate: parseDate(data.valueDate),
    rates: data.rates.map(({ asset, rate }) => ({
      asset,
      rate: parseDecimal(rate),
    })),
  };
};
