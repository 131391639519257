import { Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { DataSourceInputType } from "../../../../helper/dataSource/DataSourceInputType";
import { DataSource } from "../../../../types/dataSource/dataSource";

import { AuthoriseModule } from "../AuthoriseModule";
import { useImportContext } from "../ImportContext";
import { SyncApiParamInputs } from "./SyncApiParamInputs";

interface ApiTabProps {
  hideSyncFrom?: boolean;
  fromDate?: Date | undefined;
  dataSource: DataSource;
  customName: string | undefined;
}

export const ApiTab = ({ dataSource, fromDate, customName }: ApiTabProps) => {
  const oAuth = useMemo(
    () =>
      dataSource.syncAuthType == "oauth" || dataSource.syncAuthType == "vezgo",
    [dataSource]
  );

  const {
    state: { authorised, authInProgress, newWalletId, importType },
    actions: {
      setAuthorised,
      setAuthInProgress,
      setNewWalletId,
      setImportError,
      setImportState,
    },
  } = useImportContext();

  return (
    <Flex direction="column">
      {oAuth && !authorised && (
        <AuthoriseModule
          authorised={authorised}
          authInProgress={authInProgress}
          setAuthInProgress={setAuthInProgress}
          setAuthorised={setAuthorised}
          dataSource={dataSource}
          walletId={newWalletId}
          setWalletId={setNewWalletId}
          setError={setImportError}
          setSyncStarted={() => {
            setImportState("backgroundImporting");
          }}
          fromDate={fromDate}
          customName={customName}
        />
      )}
      {!oAuth && dataSource.syncImportParams && (
        <SyncApiParamInputs
          syncImportParams={dataSource.syncImportParams}
          validate={importType == DataSourceInputType.Api}
        />
      )}
    </Flex>
  );
};
