import { Decimal } from "@syla/shared/decimal";
import { AssetType } from "@syla/shared/types/models/AssetBase";
import { sortBy } from "lodash";

export type SortableHolding = {
  marketValue?: Decimal;
  asset: { type: AssetType; code: string };
};

export const sortHoldings = <THolding extends SortableHolding>(
  holdings: THolding[]
) => {
  const sortedAlphabetic = sortBy(holdings, (holding) => holding.asset.code);

  // then by market value
  return sortedAlphabetic.sort(sortHoldingsMarketValueCompareFn);
};

export function sortHoldingsMarketValueCompareFn(
  a: SortableHolding,
  b: SortableHolding
) {
  // some market value before no market value
  if (a.marketValue && !b.marketValue) return -1;
  if (!a.marketValue && b.marketValue) return 1;

  // when neither has market value
  if (!a.marketValue && !b.marketValue) {
    // custom assets after non-custom assets
    if (a.asset.type == AssetType.Custom && !(b.asset.type == AssetType.Custom))
      return 1;
    if (!(a.asset.type == AssetType.Custom) && b.asset.type == AssetType.Custom)
      return -1;

    return 0;
  }

  // otherwise sort by market value, descending
  return a.marketValue!.comparedTo(b.marketValue!) * -1;
}
