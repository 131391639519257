import {
  Th,
  Tr,
  Td,
  VStack,
  Table,
  Tbody,
  Box,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Decimal, sumDecimalWithFunction } from "@syla/shared/decimal";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import { AssetType } from "@syla/shared/types/models/AssetBase";
import { DataSourceType } from "@syla/shared/types/models/DataSourceBase";
import {
  WalletImportMethod,
  SyncState,
  getWalletName,
} from "@syla/shared/types/models/WalletBase";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  VisibilityState,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { addDays } from "date-fns";
import { max, uniqBy, isArray } from "lodash";
import React, { useMemo, useEffect, useState } from "react";
import { useNavigator } from "../../../routers/navigator";
import { useGetWallets } from "../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useQueryGetAccount } from "../../../store/useQueryGetAccount";
import { useValueAssets } from "../../../store/useValueAssets";
import { AssetHolding, WalletResponse } from "../../../types/wallet/wallet";
import { StdVStack } from "../../atoms/Containers";
import { SkeletonRect } from "../../atoms/Skeletons";
import { StickyThead, HEADER_Z_INDEX } from "../../atoms/StickyThead";
import { tableFrameProps } from "../../atoms/tableFrameProps";
import { MultiThumbnailSelectBox } from "../../atoms/thumbnailSelectBoxVariant/multiThumbnailSelectBox/MultiThumbnailSelectBox";
import { maxAssetsInView } from "../transaction/ledgerInputConstants";
import { ColumnSelector } from "./ColumnSelector";
import {
  getBalancesTableColumns,
  Columns,
  columnDefs,
} from "./getBalancesTableColumns";

export type HoldingDetails = {
  wallet: {
    id: string;
    name: string;
    dataSource?: string;
    address?: string;
    image?: string;
    updatedAt?: Date;
    marketValue?: Decimal;
    canSync?: boolean;
    syncState?: SyncState;
  };
  holding?: AssetHolding;
};

const defaultVisibility = Object.fromEntries(
  Object.entries(columnDefs).map(([id, def]) => [
    id,
    def.optional?.defaultVisible ?? true,
  ])
);

export type ViewBy = "dataSource" | "asset";

export const BalancesTable = ({
  balanceDate,
  viewBy,
  smallScreen,
}: {
  balanceDate: Date | undefined;
  viewBy: ViewBy;
  smallScreen: boolean | undefined;
}): JSX.Element => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const byAsset = useMemo(() => viewBy == "asset", [viewBy]);

  // query for wallets data
  const { data: walletHoldingsResponse } = useGetWallets(
    accountId,
    balanceDate && {
      asOf: addDays(balanceDate, 1) /* use end of selected day */,
    }
  );

  const isLoading = !walletHoldingsResponse;

  const [columnVisibility, setColumnVisibility] =
    useState<VisibilityState>(defaultVisibility);

  const walletHoldings = useMemo(
    () =>
      walletHoldingsResponse ?? {
        wallets: genDummyWalletHoldings(),
        accountHoldings: [],
        missingHoldings: [],
      },
    [walletHoldingsResponse]
  );

  // get valuations
  const fetchValues = columnVisibility.marketValue;

  const { data: account } = useQueryGetAccount(accountId);

  const { data: valueService, isStale: valuesStale } = useValueAssets(
    account &&
      walletHoldingsResponse && {
        baseAsset: account.taxSettings.baseCurrency,
        valueDate: balanceDate,
        assets: walletHoldingsResponse.wallets
          .flatMap((wallet) => wallet.holdings?.map((h) => h.asset) ?? [])
          .concat(
            walletHoldingsResponse.accountHoldings.map(
              (holding) => holding.asset
            )
          )
          .concat(
            walletHoldingsResponse.missingHoldings.map(
              (holding) => holding.asset
            )
          ),
      },
    {
      enabled: fetchValues,
    }
  );

  const valuesLoading = !valueService || valuesStale;

  const holdingsTableData = useMemo(() => {
    return (
      walletHoldings.wallets
        // wallet holdings
        .flatMap((wallet) => {
          const walletDetails: HoldingDetails["wallet"] = {
            id: wallet._id,
            name: getWalletName({ wallet, dataSource: wallet.dataSource }),
            dataSource: wallet.dataSource.name,
            canSync: wallet.importMethod == WalletImportMethod.API,
            syncState: wallet.syncState,
            address:
              wallet.dataSource.type == DataSourceType.Blockchain ||
              wallet.dataSource.type == DataSourceType.Wallet
                ? wallet.apiKey
                : undefined,
            image: wallet.dataSource.image,
            updatedAt: max([wallet.lastSynced, wallet.lastUploaded]),
            marketValue:
              valueService &&
              wallet.holdings &&
              sumDecimalWithFunction(
                wallet.holdings.map((holding) =>
                  valueService.getValue({
                    asset: holding.asset,
                    amount: holding.balance,
                  })
                ),
                (value) => value
              ),
          };

          if (!wallet.holdings || !wallet.holdings.length)
            return {
              wallet: walletDetails,
              holding: undefined,
            } as HoldingDetails;

          return wallet.holdings.flatMap<HoldingDetails>((holding) => ({
            wallet: walletDetails,
            holding: {
              ...holding,
              marketValue: valueService?.getValue({
                asset: holding.asset,
                amount: holding.balance,
              }),
            },
          }));
        })
        // missing holdings
        .concat(
          walletHoldings.missingHoldings?.length
            ? walletHoldings.missingHoldings.flatMap((holding) => ({
                wallet: {
                  id: "missing",
                  name: "Missing Balances",
                  image: "/cloud.png",
                  updatedAt: max(
                    walletHoldings.wallets.map((wallet) =>
                      max([wallet.lastSynced, wallet.lastUploaded])
                    )
                  ),
                  marketValue:
                    valueService &&
                    sumDecimalWithFunction(
                      walletHoldings.missingHoldings.map((holding) =>
                        valueService.getValue({
                          asset: holding.asset,
                          amount: holding.balance,
                        })
                      ),
                      (value) => value?.abs()
                    ),
                },
                holding: {
                  ...holding,
                  marketValue: valueService?.getValue({
                    asset: holding.asset,
                    amount: holding.balance,
                  }),
                },
              }))
            : []
        )
        // account holdings (total portfolio)
        .concat(
          byAsset
            ? []
            : walletHoldings.accountHoldings.length
            ? walletHoldings.accountHoldings.flatMap((holding) => ({
                wallet: {
                  id: "total",
                  name: "Total Portfolio",
                  image: "/syla-custom.png",
                  updatedAt: max(
                    walletHoldings.wallets.map((wallet) =>
                      max([wallet.lastSynced, wallet.lastUploaded])
                    )
                  ),
                  marketValue:
                    valueService &&
                    sumDecimalWithFunction(
                      walletHoldings.accountHoldings.map((holding) =>
                        valueService.getValue({
                          asset: holding.asset,
                          amount: holding.balance,
                        })
                      ),
                      (value) => value
                    ),
                },
                holding: {
                  ...holding,
                  marketValue: valueService?.getValue({
                    asset: holding.asset,
                    amount: holding.balance,
                  }),
                },
              }))
            : [
                {
                  wallet: {
                    id: "0",
                    name: "Total Portfolio",
                    image: "/android-chrome-192x192.png",
                  },
                  holding: undefined,
                } as HoldingDetails,
              ]
        )
        .filter((holding) => viewBy != "asset" || !!holding.holding)
    );
  }, [
    walletHoldings.wallets,
    walletHoldings.missingHoldings,
    walletHoldings.accountHoldings,
    byAsset,
    valueService,
    viewBy,
  ]);

  const navigate = useNavigator();

  const table = useReactTable({
    columns: getBalancesTableColumns({
      accountId,
      marketValueLoading: valuesLoading,
      navigate,
      balanceDate,
    }),
    data: holdingsTableData,
    state: { columnVisibility },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetExpanded: false,
    onColumnVisibilityChange: setColumnVisibility,
    keepPinnedRows: true,
  });

  // update viewBy state
  useEffect(() => {
    const dataSourceCol = table.getColumn(Columns.dataSource)!;
    const assetCol = table.getColumn(Columns.asset)!;

    if (viewBy == "dataSource") {
      if (!dataSourceCol.getIsGrouped()) {
        dataSourceCol.toggleGrouping();
      }
      if (assetCol.getIsGrouped()) {
        assetCol.toggleGrouping();
      }
    }
    if (viewBy == "asset") {
      if (!assetCol.getIsGrouped()) {
        assetCol.toggleGrouping();
      }
      if (dataSourceCol.getIsGrouped()) {
        dataSourceCol.toggleGrouping();
      }
    }

    // repin column
    table.getColumn(Columns.expand)!.pin("left");
    // reset expansion state
    table.toggleAllRowsExpanded(false);
  }, [table, viewBy]);

  // useEffect(() => {
  //   if (balanceDate && columnVisibility[currentDateOnlyColumns[0]]) {
  //     setColumnVisibility({
  //       ...columnVisibility,
  //       ...Object.fromEntries(currentDateOnlyColumns.map((c) => [c, false])),
  //     });
  //   } else if (!balanceDate && !columnVisibility[currentDateOnlyColumns[0]]) {
  //     setColumnVisibility({
  //       ...columnVisibility,
  //       ...Object.fromEntries(currentDateOnlyColumns.map((c) => [c, true])),
  //     });
  //   }
  // }, [balanceDate, columnVisibility]);

  // Filters

  const [filters, setFilters] = useState<{
    assets?: string[];
    dataSources?: string[];
  }>({});

  // apply filter values
  useEffect(() => {
    const assetCol = table.getColumn(Columns.asset)!;
    const dsCol = table.getColumn(Columns.dataSource)!;

    // disable filters when loading.
    // no need to filter the dummy data
    if (isLoading) {
      assetCol.setFilterValue(undefined);
      dsCol.setFilterValue(undefined);
      return;
    }

    assetCol.setFilterValue(
      filters.assets?.length ? filters.assets : undefined
    );

    dsCol.setFilterValue(
      filters.dataSources?.length ? filters.dataSources : undefined
    );
  }, [filters, table, isLoading]);

  const filtersActive = useMemo(() => {
    const appliedFilters = Object.values(filters).filter((filterValue) =>
      isArray(filterValue) ? filterValue.length : filterValue
    );
    return !!appliedFilters.length;
  }, [filters]);

  const datasourceFilterOptions: SelectOptionType[] = useMemo(
    () =>
      uniqBy(
        holdingsTableData.map((h) => h.wallet),
        (wallet) => wallet.id
      ).map((wallet) => ({
        value: wallet.id,
        label: wallet.name,
        icon: wallet.image,
      })),
    [holdingsTableData]
  );

  const assetFilterOptions: SelectOptionType[] = useMemo(
    () =>
      uniqBy(
        withoutNullable(holdingsTableData.map((h) => h.holding?.asset)),
        (asset) => asset._id
      ).map((asset) => ({
        value: asset._id,
        label: getAssetShortName(asset),
        icon: asset.image,
      })),
    [holdingsTableData]
  );

  const columnSpacing = 0;

  return (
    <StdVStack>
      {/* Filter line */}
      <HStack justify="space-between" w="100%">
        {/* Filters */}
        <HStack>
          <MultiThumbnailSelectBox
            searchEnable
            selectedOptions={filters.dataSources ?? []}
            onChangeSelection={(selection) => {
              setFilters((state) => ({ ...state, dataSources: selection }));
            }}
            options={datasourceFilterOptions}
            maxOptionsInView={5}
            placeholder="Data Source"
            selectBtnProps={{ height: "30px" }}
            optionContainerProps={{
              width: "250px",
              top: "30px",
              zIndex: HEADER_Z_INDEX + 1,
            }}
          />
          <MultiThumbnailSelectBox
            searchEnable
            doubleRows={!smallScreen}
            selectedOptions={filters?.assets ?? []}
            onChangeSelection={(selection) => {
              setFilters((state) => ({ ...state, assets: selection }));
            }}
            options={assetFilterOptions}
            maxOptionsInView={maxAssetsInView}
            placeholder="Asset"
            selectBtnProps={{ height: "30px" }}
            optionContainerProps={{
              width: "450px",
              top: "30px",
              zIndex: HEADER_Z_INDEX + 1,
            }}
          />

          {filtersActive && (
            <Button
              variant="ghost"
              onClick={() => {
                setFilters({});
              }}
              fontSize="0.875rem"
              h="28px"
              color="red.500"
            >
              Clear Filters
            </Button>
          )}
        </HStack>

        {/* Export and Customise */}
        <HStack>
          <ColumnSelector
            state={columnVisibility}
            setState={setColumnVisibility}
          />
        </HStack>
      </HStack>
      <Box w="100%" minW="fit-content" {...tableFrameProps}>
        <Table>
          <StickyThead>
            <Tr>
              {/* Render pinned headers */}
              {table.getLeftFlatHeaders().map((header) => (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  pe={0}
                  width="1rem"
                >
                  <VStack alignItems="flex-start">
                    {/* Handles all possible header column def scenarios for `header` */}
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </VStack>
                </Th>
              ))}
              {/* Render regular headers */}
              {table.getCenterFlatHeaders().map((header, headerIndex) => (
                <Th
                  key={header.id}
                  colSpan={header.colSpan}
                  pe={columnSpacing}
                  ps={headerIndex == 0 ? 0 : "8px"}
                >
                  <VStack alignItems="flex-start">
                    {/* Handles all possible header column def scenarios for `header` */}
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </VStack>
                </Th>
              ))}
            </Tr>
          </StickyThead>
          <Tbody>
            {table.getCenterRows().map((row) => (
              <Tr
                key={row.id}
                onClick={
                  row.getCanExpand()
                    ? row.getToggleExpandedHandler()
                    : undefined
                }
                {...(row.getCanExpand()
                  ? {
                      _hover: { bg: "gray.100" },
                      cursor: "pointer",
                    }
                  : {})}
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <Td
                    key={cell.id}
                    ps={cellIndex == 1 ? 0 : undefined}
                    pe={cellIndex == 0 ? 0 : undefined}
                    width={cellIndex == 0 ? "1rem" : undefined}
                    py="8px"
                  >
                    <SkeletonRect isLoaded={!isLoading}>
                      {cell.getIsAggregated()
                        ? // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          flexRender(
                            cell.column.columnDef.aggregatedCell ??
                              cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        : cell.getIsPlaceholder()
                        ? null // For cells with repeated values, render null
                        : // Otherwise, just render the regular cell
                          flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                    </SkeletonRect>
                  </Td>
                ))}
              </Tr>
            ))}
            {/*{table.getBottomRows().map((row) => (*/}
            {/*  <Tr key={row.id}>*/}
            {/*    {row.getVisibleCells().map((cell) => (*/}
            {/*      <Td key={cell.id} pe={columnSpacing}>*/}
            {/*        {cell.getIsAggregated()*/}
            {/*          ? // If the cell is aggregated, use the Aggregated*/}
            {/*            // renderer for cell*/}
            {/*            flexRender(*/}
            {/*              cell.column.columnDef.aggregatedCell ??*/}
            {/*                cell.column.columnDef.cell,*/}
            {/*              cell.getContext()*/}
            {/*            )*/}
            {/*          : cell.getIsPlaceholder()*/}
            {/*          ? null // For cells with repeated values, render null*/}
            {/*          : // Otherwise, just render the regular cell*/}
            {/*            flexRender(cell.column.columnDef.cell, cell.getContext())}*/}
            {/*      </Td>*/}
            {/*    ))}*/}
            {/*  </Tr>*/}
            {/*))}*/}
          </Tbody>
        </Table>
      </Box>
    </StdVStack>
  );
};

const genDummyWalletHoldings = () =>
  Array.from({ length: 8 }).map<WalletResponse>(
    (_, index) =>
      ({
        _id: index,
        dataSource: { _id: "0", name: "", type: DataSourceType.Wallet },
        holdings: [
          {
            balance: Decimal.from("0"),
            asset: {
              _id: index,
              type: AssetType.Token,
              name: "",
              code: "",
            },
          },
        ],
      } as any)
  );
