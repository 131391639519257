import React, { useMemo } from "react";
import { ImportParamBase } from "@syla/shared/types/models/DataSourceBase";
import { sortBy } from "lodash";
import { useFormContext } from "react-hook-form";
import {
  AddDataSourceForm,
  DataSource,
} from "../../../../types/dataSource/dataSource";
import { ParamInput } from "../ParamInput";

export const SyncApiParamInputs = ({
  syncImportParams,
  validate,
}: { validate: boolean } & Required<Pick<DataSource, "syncImportParams">>) => {
  // hook form context
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const importParams = useMemo(
    () => ({
      key: null,
      secret: null,
      passphrase: null,
    }),
    []
  );

  const sortedParams = useMemo(() => {
    if (!syncImportParams) throw new Error("Missing params");
    return sortBy(
      Object.entries(syncImportParams).filter(
        ([key]) => key in importParams
      ) as [keyof typeof importParams, ImportParamBase][],
      ([, { order }]) => order
    );
  }, [importParams, syncImportParams]);

  const mapParamProp: (
    param: keyof typeof importParams
  ) => keyof AddDataSourceForm = (param) => {
    switch (param) {
      case "key":
        return "apiKey";
      case "secret":
        return "secretKey";
      case "passphrase":
        return "passphrase";
    }
  };

  return (
    <>
      {sortedParams.map(([param, { text }]) =>
        ParamInput({
          text,
          isDisabled: isSubmitting,
          error: errors[mapParamProp(param)],
          register: register(mapParamProp(param), {
            required: validate ? `${text} is required` : undefined,
          }),
          showLock: param != "key",
        })
      )}
    </>
  );
};
