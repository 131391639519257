import { sumWithDecimal, Decimal } from "@syla/shared/decimal";
import { getLastFinancialYear } from "@syla/shared/helpers/financialYear";
import {
  premiumAccountType,
  premiumAccountTypeRequirement,
} from "@syla/shared/types/helpers/permissions";
import {
  getCurrentSubscription,
  planHasCapacity,
} from "@syla/shared/types/helpers/subscriptions";
import { WithModelIds } from "@syla/shared/types/helpers/WithModelIds";
import { AccountSubType } from "@syla/shared/types/models/AccountBase";
import { PlanBase } from "@syla/shared/types/models/PlanBase";
import { sortBy } from "lodash";
import { useQuery } from "react-query";
import { getPlans } from "../api/plans/getPlans";
import { getAccountTransactionCount } from "../api/transactions/getAccountTransactionCount";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { useGlobalVariablesStore } from "../store/globalVariablesStore";
import {
  useTransactionsStore,
  getAccountTransactionsCacheKey,
} from "../store/transactionsStore";
import { useQueryGetAccount } from "../store/useQueryGetAccount";
import { useQueryModelTaxResult } from "../store/useQueryModelTaxResult";
import { useQuerySubscriptions } from "../store/useQuerySubscriptions";

type Plan = WithModelIds<PlanBase<any>>;

// determine recommended plan and other things
export const useGetAccountPlanProps = () => {
  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );
  const financialYear = selectedFinancialYear?.value ?? getLastFinancialYear();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: account, isStale: accountStale } =
    useQueryGetAccount(accountId);

  const { data: getPlansResponse, isLoading: plansLoading } = useQuery(
    "plans",
    getPlans
  );

  const { data: txCount, isLoading: txCountLoading } = useQuery(
    [...getAccountTransactionsCacheKey(accountId), "txCount"],
    () => getAccountTransactionCount(accountId),
    { refetchOnWindowFocus: false }
  );
  const hasTxCount = txCount != null;

  const { data: getSubscriptionsResponse, isLoading: subscriptionsLoading } =
    useQuerySubscriptions();

  const subscriptions = getSubscriptionsResponse?.subscriptions ?? [];
  const currentSubscription = getCurrentSubscription(subscriptions);
  const currentPlan = currentSubscription?.plan;
  //
  // const permissionToUpgrade = canUpgradeSubscription({ subscriptions });
  // const permissionToPurchase = canPurchaseSubscription({
  //   account,
  //   subscriptions,
  // });

  const plans = sortBy(
    (getPlansResponse?.plans ?? []).filter(
      (plan) =>
        plan._id == currentPlan?._id ||
        (plan.active &&
          // if we are still on an archived plan, don't show the new plan of the same name
          (currentPlan?.active || plan.name != currentPlan?.name))
    ),
    (plan) => plan.price
  );

  const isRecalculating = useTransactionsStore((state) => state.updating);

  const validPlans = plans.filter(
    (plan) => hasTxCount && planHasCapacity({ plan, transactionCount: txCount })
  );

  const possibleUpgradePlans = validPlans.filter(
    (plan) => !currentPlan || plan.price > currentPlan.price
  );

  let recommendedPlan: Plan | undefined = undefined;
  let modelSaving = false;
  let capExceeded = false;
  let premiumEntity = false;
  let tradingStock = false;
  let ltfoSaving = false;

  if (
    !possibleUpgradePlans.length ||
    !account ||
    accountStale ||
    !hasTxCount
    // (!currentSubscription && !permissionToPurchase) || // don't upsell if no permisison to purchase
    // (currentSubscription && !permissionToUpgrade) // don't do upsell if no permission to upgrade
  ) {
    recommendedPlan = undefined;
  } else if (
    !planHasCapacity({
      plan: currentPlan ?? plans[0],
      transactionCount: txCount,
    })
  ) {
    // transaction cap exceeded. Pick next plan which can handle
    capExceeded = true;
    recommendedPlan = possibleUpgradePlans.find(
      (plan) =>
        premiumAccountTypeRequirement({ account, plan }) &&
        planHasCapacity({
          plan,
          transactionCount: txCount,
        })
    );
  } else if (
    premiumAccountType(account.accountType) &&
    !currentPlan?.premiumFeatures
  ) {
    // wrong plan for account type
    premiumEntity = true;
    recommendedPlan = possibleUpgradePlans.find((plan) => plan.premiumFeatures);
  } else if (
    account.accountSubType == AccountSubType.Trader &&
    !currentPlan?.premiumFeatures
  ) {
    // wrong plan for account type
    tradingStock = true;
    recommendedPlan = possibleUpgradePlans.find((plan) => plan.premiumFeatures);
  } else if (!currentPlan?.taxOptimisationFeatures) {
    // calculate possible savings to determine plan recommendation
    modelSaving = true;
  }

  // if modelling saving
  const { data: modelTaxResultResponse, isStale: modelTaxResultStale } =
    useQueryModelTaxResult({
      financialYearValue: financialYear,
      enabled: modelSaving && !isRecalculating,
    });

  const totalGainDifference =
    modelTaxResultResponse &&
    sumWithDecimal(modelTaxResultResponse.taxResultSavings, "gain");

  if (modelSaving && totalGainDifference) {
    const ltfoSavingPlan = possibleUpgradePlans.find(
      (plan) =>
        plan.taxOptimisationFeatures &&
        totalGainDifference.gt(
          getUpgradeCost({ currentPlan, possiblePlan: plan })
        )
    );

    if (ltfoSavingPlan) {
      recommendedPlan = ltfoSavingPlan;
      ltfoSaving = true;
    }
  }

  // default to assurance if none selected so far
  if (!recommendedPlan) {
    recommendedPlan = possibleUpgradePlans.find(
      (plan) => plan.assuranceFeatures
    );
  }

  const isLoading =
    accountStale ||
    plansLoading ||
    txCountLoading ||
    subscriptionsLoading ||
    (modelSaving && (modelTaxResultStale || isRecalculating));

  const result = {
    currentPlan,
    subscriptions,
    plans,
    validPlans,
    recommendedPlan,
    upgradeCost:
      recommendedPlan &&
      getUpgradeCost({ currentPlan, possiblePlan: recommendedPlan }),
    totalGainDifference,
    yearlySavings: modelTaxResultResponse?.taxResultSavings,
    isLoading,
    txCount: hasTxCount ? { value: txCount } : undefined, // reduce possible errors by wrapping number
    capExceeded,
    premiumEntity,
    tradingStock,
    ltfoSaving,
    account,
  };

  // console.debug("getUserPlanProps", result);

  return result;
};

// helper
export const findPlan = (plans: Plan[] | undefined, id: Plan["_id"]) =>
  (plans ?? []).find((plan) => plan._id == id);

const getUpgradeCostCents = ({
  currentPlan,
  possiblePlan,
}: {
  currentPlan: Plan | undefined;
  possiblePlan: Plan;
}) => possiblePlan.price - (currentPlan?.price ?? 0);

const getUpgradeCost = (props: {
  currentPlan: Plan | undefined;
  possiblePlan: Plan;
}) => Decimal.from(getUpgradeCostCents(props).toString()).div("100"); // todo round to dollar
