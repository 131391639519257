import {
  Divider,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FinancialYear } from "@syla/shared/types/models/FinancialYear";
import { TransactionType } from "@syla/shared/types/models/TransactionBase";
import { StatusOption } from "@syla/shared/types/requests/GetGroupsRequest";
import React from "react";

import { transactionTypeOptions } from "../../../helper/transaction/getTransactionTypeOptions";
import { useNavigator, Route } from "../../../routers/navigator";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { FinancialYearOption } from "../../../types/user/financialYearOption";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import {
  ContentHeadingContainer,
  CardContentContainer,
} from "../../atoms/Containers";
import { TaxReportHeading } from "../../atoms/Headings";
import { ImageWithMissingSrc } from "../../atoms/ImageWithMissingSrc";
import { sylaSuggestionsText } from "../../atoms/NeedsReview";
import { SkeletonRect } from "../../atoms/Skeletons";
import { MainTransactionTableHistoryState } from "../transaction/mainTransactionTable/TransactionsView";
import { useTaxReportSummaryQuery } from "./useTaxReportSummaryQuery";

export const TransactionSummary = ({
  selectedFinancialYear,
}: {
  selectedFinancialYear?: FinancialYearOption;
}): React.ReactElement => {
  const navigate = useNavigator();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { data, isLoading, isStale } = useTaxReportSummaryQuery(
    selectedFinancialYear
  );

  const taxReportSummary = data ?? {
    // dummy rows while loading
    transactionTypeCounts: [
      {
        type: TransactionType.Trade,
        numTransactions: 10000,
        toReview: 0,
      },

      {
        type: TransactionType.Receive,
        numTransactions: 10000,
        toReview: 0,
      },
      {
        type: TransactionType.Send,
        numTransactions: 10000,
        toReview: 0,
      },
    ],
  };

  const showLoading = isLoading || isStale || !selectedFinancialYear;

  return (
    <CardContentContainer>
      <ContentHeadingContainer>
        <TaxReportHeading>Transaction Summary</TaxReportHeading>
        <ButtonVariant
          content="View all"
          outlineType="outlineLightGray"
          onClick={() => navigate({ route: Route.Transactions, accountId })}
          fontWeight="normal"
          leftIcon="external"
        />
      </ContentHeadingContainer>
      <Divider mt="20px" />
      <Box overflowX={"auto"}>
        <Table>
          <Thead>
            <Tr>
              <THeaderCell>Value</THeaderCell>
              <THeaderCell textAlign="center">Amount</THeaderCell>
              <THeaderCell textAlign="center">
                {sylaSuggestionsText}
              </THeaderCell>
            </Tr>
          </Thead>
          <Tbody>
            {!taxReportSummary?.transactionTypeCounts.length && (
              <Tr p="0">
                <TCell colSpan={3} textAlign="center" fontSize="0.875rem">
                  <Text>No transactions in this financial year</Text>
                </TCell>
              </Tr>
            )}
            {taxReportSummary?.transactionTypeCounts.map((item) => (
              <Tr key={item.type} p="0">
                <TCell>
                  <Flex direction="row" alignItems="center">
                    <ImageWithMissingSrc
                      src={transactionTypeOptions[item.type].icon}
                      alt=""
                      mr="20px"
                      h="24px"
                      isLoading={showLoading}
                    />
                    <SkeletonRect isLoaded={!showLoading}>
                      <Text fontSize="0.875rem" color="black.900">
                        {transactionTypeOptions[item.type].label}
                      </Text>
                    </SkeletonRect>
                  </Flex>
                </TCell>
                <TCell textAlign="center" fontSize="0.875rem" color="black.700">
                  <SkeletonRect isLoaded={!showLoading}>
                    {item.numTransactions}
                  </SkeletonRect>
                </TCell>
                {showLoading ? (
                  <TCell textAlign="center">
                    <SkeletonRect>10</SkeletonRect>
                  </TCell>
                ) : (
                  <TCell
                    color="red.500"
                    fontWeight="500"
                    textDecor="underline"
                    textAlign="center"
                    cursor="pointer"
                    onClick={() => {
                      const state: MainTransactionTableHistoryState = {
                        filter: {
                          transactionType: [item.type],
                          status: [StatusOption.NeedsReview],
                          date: selectedFinancialYear && {
                            financialYear: new FinancialYear({
                              value: selectedFinancialYear.value,
                            }),
                          },
                        },
                      };
                      // navigate to the transactions screen
                      // pre-filter by the transaction type and needs review state
                      navigate({
                        route: Route.Transactions,
                        accountId,
                        state,
                      });
                    }}
                  >
                    <SkeletonRect isLoaded={!showLoading}>
                      {item.toReview}
                    </SkeletonRect>
                  </TCell>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </CardContentContainer>
  );
};

const THeaderCell = styled(Th)`
  color: #666;
  font-size: 0.75rem;
  font-weight: 500;
`;

const TCell = styled(Td)`
  padding: 10px 20px;
`;
