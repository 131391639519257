import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { getWalletName } from "@syla/shared/types/models/WalletBase";
import React from "react";
import { Flex, Text, Image, Tooltip, Icon } from "@chakra-ui/react";
import { BsChevronRight } from "react-icons/bs";

import { NumberStyler } from "../../../../../helper/NumberStyler";
import { getTransactionTypeIcon } from "../../../../../helper/transaction/getTransactionTypeOptions";
import { getTradeTypeIconAndTextFromTransaction } from "../../../../../helper/transaction/getTradeTypeIconAndText";
import { useTransactionValues } from "../../../../../hooks/useTransactionValues";
import { Transaction } from "../../../../../types/transaction";
import {
  needsReviewIconProps,
  transactionTooltipText,
} from "../../../../atoms/NeedsReview";
import { SliceWords } from "../../SliceWords";
import { RenderAssetsIcon } from "../RenderAssetsIcon";

export const SubTransactionRow = ({
  transaction,
  onClick,
}: {
  transaction: Transaction;
  onClick: () => void;
}): JSX.Element => {
  const { negativeChanges, positiveChanges } = useTransactionValues([
    transaction,
  ]);

  const showNeedsReviewIndicator = !!transaction.needsReview?.length;
  const { icon, text } = getTradeTypeIconAndTextFromTransaction(transaction);

  return (
    <Flex
      alignItems="center"
      justifyContent="space-around"
      p=" 20px 30px"
      w="95%"
      m="0 auto 8px auto"
      bgColor="white.100"
      onClick={onClick}
      cursor="pointer"
    >
      {/* --------------------------- Asset --------------------------- */}
      <Flex w="25%" alignItems="center">
        <Image src={icon} alt="" />
        <Text>{text}</Text>
      </Flex>

      {/* --------------------------- Out --------------------------- */}
      <Flex w="32%">
        {negativeChanges.length > 0 && (
          <>
            <RenderAssetsIcon
              icons={negativeChanges.map((bd) => bd.asset.image)}
            />
            <Flex direction="column">
              <Flex alignItems="center">
                <SliceWords dataSources={[getWalletName(transaction)]} />
              </Flex>
              <Flex>
                <Text color="red.500" fontSize="0.75rem" fontWeight="500">
                  -
                </Text>
                <NumberStyler
                  num={negativeChanges[0].amount}
                  mx="5px"
                  color="black.700"
                  fontSize="0.75rem"
                  fontWeight="500"
                  assetInfo={negativeChanges[0].asset}
                  signed={false}
                />
                <Text color="black.700" fontSize="0.75rem" fontWeight="500">
                  {getAssetShortName(negativeChanges[0].asset)}
                </Text>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      {/* --------------------------- In --------------------------- */}
      <Flex w="32%">
        {positiveChanges.length > 0 && (
          <>
            <RenderAssetsIcon
              icons={positiveChanges.map((bd) => bd.asset.image)}
            />
            <Flex direction="column">
              <Flex alignItems="center">
                <SliceWords dataSources={[getWalletName(transaction)]} />
              </Flex>
              <Flex>
                <Text color="green.500" fontSize="0.75rem" fontWeight="500">
                  +
                </Text>
                <NumberStyler
                  num={positiveChanges[0].amount}
                  mx="5px"
                  color="black.700"
                  fontSize="0.75rem"
                  fontWeight="500"
                  assetInfo={positiveChanges[0].asset}
                  signed={false}
                />
                <Text color="black.700" fontSize="0.75rem" fontWeight="500">
                  {getAssetShortName(positiveChanges[0].asset)}
                </Text>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      {/* Needs Review Indicator */}
      {showNeedsReviewIndicator && (
        <Flex w="5%">
          <Tooltip label={transactionTooltipText} fontSize="md">
            <Icon {...needsReviewIconProps} />
          </Tooltip>
        </Flex>
      )}
      <BsChevronRight color="#ff3600" size="1.7rem" />
    </Flex>
  );
};
