import { Decimal } from "@syla/shared/decimal";
import { Id } from "@syla/shared/types/helpers/Id";
import { ValueAssetsResponse } from "../api/asset/valueAssets";

export class AssetValueService {
  public readonly baseAsset: Id<string>;
  public readonly valueDate: Date;
  private readonly _rateLookup: Map<string, Decimal>;

  constructor({ baseAsset, valueDate, rates }: ValueAssetsResponse) {
    this.baseAsset = baseAsset;
    this.valueDate = valueDate;
    this._rateLookup = new Map(
      rates.map(({ asset: { _id }, rate }) => [_id, rate])
    );
  }

  public getValue(props: {
    asset: Id<string>;
    amount: Decimal;
  }): Decimal | undefined {
    const rate = this._rateLookup.get(props.asset._id);
    if (!rate) return undefined;

    return props.amount.times(rate);
  }
}
