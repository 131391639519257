import { TransactionType } from "@syla/shared/types/models/TransactionBase";
import { splitTransactionLedgers } from "../ledger/splitTransactionLedgers";
import { OrderResponse } from "../../types/order/orderRespond";
import { Transaction } from "../../types/transaction";
import { getLedgerTypeIcon } from "./getLedgerType";
import { getTransactionTypeIcon } from "./getTransactionTypeOptions";

export const getTradeTypeIconAndTextFromOrder = (
  orderResponse: OrderResponse
): { icon: string; text: string } => {
  // if more than one transaction in the order, return the order icon
  if (orderResponse.transactions.length > 1)
    return {
      icon: getTransactionTypeIcon("group"),
      text: "Group",
    };

  return getTradeTypeIconAndTextFromTransaction(orderResponse.transactions[0]);
};

export const getTradeTypeIconAndTextFromTransaction = (
  transaction: Transaction
): { icon: string; text: string } => {
  const { allInLedgers, allOutLedgers } = splitTransactionLedgers([
    transaction,
  ]);

  // if transaction type is send, return the out ledger type icon
  if (transaction.type === TransactionType.Send && allOutLedgers.length)
    return {
      icon: getLedgerTypeIcon(allOutLedgers[0].type),
      text: allOutLedgers[0].type,
    };

  // if transaction type is receive, return the in ledger type icon
  if (transaction.type === TransactionType.Receive && allInLedgers.length)
    return {
      icon: getLedgerTypeIcon(allInLedgers[0].type),
      text: allInLedgers[0].type,
    };

  // return the transaction type icon
  return {
    icon: getTransactionTypeIcon(transaction.type),
    text: transaction.type,
  };
};
