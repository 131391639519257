import {
  Flex,
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tag,
  Tab,
} from "@chakra-ui/react";
import { getShortContractAddress } from "@syla/shared/helpers/assets/getShortContractAddress";
import {
  getDataSourceSupportedInputs,
  DataSourceType,
  ImportStatus,
} from "@syla/shared/types/models/DataSourceBase";
import React, { useState, useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { DataSourceInputType } from "../../../helper/dataSource/DataSourceInputType";
import {
  DataSource,
  AddDataSourceForm,
} from "../../../types/dataSource/dataSource";
import { FileImportTab } from "../../atoms/FileImportTab";
import { ImageWithMissingSrc } from "../../atoms/ImageWithMissingSrc";
import { ApiTab } from "./addDataSourceDrawer/ApiTab";
import { AssistedFileImportTab } from "./AssistedFileImportTab";
import { DataSourceNameInput } from "./DataSourceNameInput";
import { getInputTypesPerTabIndex } from "./getInputTypesPerTabIndex";
import { useImportContext } from "./ImportContext";
import { ImportStatusIndicator } from "./ImportStatusIndicator";

export const ImportDataSource = ({
  nameRequired,
  selectedDataSource,
}: {
  selectedDataSource: DataSource;
  nameRequired: boolean;
}) => {
  const {
    state: {
      importState,
      importInProgress,
      importComplete,
      authInProgress,
      importType,
    },
    actions: { setImportType },
  } = useImportContext();

  const {
    register,
    resetField,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<AddDataSourceForm>();

  const [tabIndex, setTabIndex] = useState(0);

  const supportedInputs = useMemo(
    () => getDataSourceSupportedInputs(selectedDataSource),
    [selectedDataSource]
  );

  useEffect(() => {
    const tabInputType = getInputTypesPerTabIndex(supportedInputs);
    setImportType(tabInputType[tabIndex]);
    // clear specific form fields on tab change
    resetField("apiKey");
    resetField("secretKey");
    resetField("passphrase");
    resetField("fromDate");
    resetField("storedFiles");
  }, [resetField, setImportType, supportedInputs, tabIndex]);

  // pre-set custom name when entering wallet address
  const apiKey = watch("apiKey")?.trim();
  useEffect(() => {
    if (
      !(
        selectedDataSource.type == DataSourceType.Blockchain ||
        selectedDataSource.type == DataSourceType.Wallet
      )
    )
      return;

    if (apiKey) {
      // shorten key to create custom name
      const walletAddressId = apiKey.replace(/^(bitcoincash:|ecash:)/i, "");

      setValue(
        "customName",
        `${selectedDataSource.name} (${getShortContractAddress(
          walletAddressId
        )})`
      );
    } else {
      resetField("customName");
    }
  }, [apiKey, resetField, selectedDataSource, setValue]);

  const tabsDisabled = importInProgress || authInProgress || importComplete;

  return (
    <>
      <Flex justifyContent="center" alignItems="center" mt="10px" mb="30px">
        <ImageWithMissingSrc
          src={selectedDataSource.image}
          noPaddingIcon
          alt=""
          mr="10px"
          h="40px"
          borderRadius="20px"
        />
        <Text fontSize="1.1rem" fontWeight="bold">
          {watch("customName")}
        </Text>
      </Flex>
      <Tabs
        w="99%"
        mx="auto"
        defaultIndex={0}
        tabIndex={tabIndex}
        onChange={setTabIndex}
      >
        <TabList>
          {supportedInputs.sync ? (
            <Tab w="50%" isDisabled={tabsDisabled}>
              <Text>
                Sync
                <Tag
                  bgColor="primary.50"
                  color="primary.700"
                  size="sm"
                  ml="10px"
                >
                  Best
                </Tag>
              </Text>
            </Tab>
          ) : undefined}
          {supportedInputs.file ? (
            <Tab w="50%" isDisabled={tabsDisabled}>
              File Import
            </Tab>
          ) : undefined}
          {selectedDataSource.type != DataSourceType.Import && (
            <Tab w="50%" isDisabled={tabsDisabled}>
              Manual Import
            </Tab>
          )}
        </TabList>
        <TabPanels>
          {supportedInputs.sync ? (
            <TabPanel>
              {importState ? (
                <ImportStatusIndicator
                  selectedDataSource={selectedDataSource}
                  importType={importType}
                  importState={importState}
                />
              ) : (
                <ApiTab
                  dataSource={selectedDataSource}
                  fromDate={watch("fromDate")}
                  customName={watch("customName")}
                />
              )}
            </TabPanel>
          ) : undefined}
          {supportedInputs.file ? (
            <TabPanel>
              {supportedInputs.file == ImportStatus.Alpha &&
              // only render when tab selected, due to Intercom only triggering on one button per page
              importType == DataSourceInputType.Csv ? (
                <AssistedFileImportTab />
              ) : importState ? (
                <ImportStatusIndicator
                  selectedDataSource={selectedDataSource}
                  importType={importType}
                  importState={importState}
                />
              ) : (
                <FileImportTab />
              )}
            </TabPanel>
          ) : undefined}
          {selectedDataSource.type != DataSourceType.Import && (
            <TabPanel>
              {importState ? (
                <ImportStatusIndicator
                  selectedDataSource={selectedDataSource}
                  importType={importType}
                  importState={importState}
                />
              ) : (
                <>
                  {nameRequired && (
                    <>
                      <DataSourceNameInput
                        isRequired={true}
                        registerProps={register("customName", {
                          required: "Please enter a name",
                          minLength: 1,
                        })}
                        error={errors.customName?.message}
                      />
                    </>
                  )}
                  <FileImportTab />
                </>
              )}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </>
  );
};
