import {
  Input,
  Text,
  Flex,
  useDisclosure,
  Button,
  FormControl,
} from "@chakra-ui/react";
import { get } from "lodash";
import { TransactionType } from "@syla/shared/types/models/TransactionBase";
import React, { useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";

import { SingleThumbnailSelectBox } from "../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";
import { maxAssetsToDisplay, maxAssetsInView } from "../ledgerInputConstants";
import { amountInvalid } from "../../../../helper/forms/stringAsNumberInputField";

export const LedgerInputs = ({
  name,
  list,
}: {
  name: string;
  list: SelectOptionType[];
}): JSX.Element => {
  const {
    setValue,
    control,
    formState: { errors },
    watch,
  } = useFormContext<any>();

  const {
    isOpen,
    onClose: closeCustomMarketValueInput,
    onToggle,
  } = useDisclosure();

  const transactionType: TransactionType = watch("type");

  useEffect(() => {
    closeCustomMarketValueInput();
    setValue(`${name}.customMarketValue`, undefined);
  }, [closeCustomMarketValueInput, name, setValue, transactionType]);

  return (
    <Flex direction="column" w="100%">
      <Flex
        direction="row"
        alignItems="center"
        mb="15px"
        justifyContent="space-between"
      >
        {/* ----------------------- ledger amount input --------------------- */}
        <FormControl isInvalid={!!get(errors, `${name}.amount.type`)}>
          <Controller
            control={control}
            name={`${name}.amount`}
            rules={{
              required: !name.includes("feeLedgers"),
              validate: (value) => {
                // for fee ledger, allow empty value or valid amount
                // for others, there are required so only allow valid amount
                if (name.includes("feeLedgers"))
                  return !value || !amountInvalid({ value });
                else return !amountInvalid({ value });
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                // NOTE: transactions no longer have the fee ledgers array, the hook form will keep it to maintain a single form for fees and just build the alt in ledgers and alt out ledgers array in the on submit handler
                isRequired={!name.includes("feeLedgers")}
                value={value}
                type="string"
                placeholder="Quantity"
                borderColor="#f5f5f5"
                h="50px"
                width="100%"
                _focus={{ boxShadow: "none", borderColor: "#000" }}
                onChange={(event) => onChange(event.target.value)}
              />
            )}
          />
        </FormControl>

        {/* ----------------------- ledger asset select box --------------------- */}
        <Controller
          // NOTE: transactions no longer have the fee ledgers array, the hook form will keep it to maintain a single form for fees and just build the alt in ledgers and alt out ledgers array in the on submit handler
          control={control}
          name={`${name}.assetId`}
          rules={{ required: !name.includes("feeLedgers") }}
          render={({ field: { onChange, value } }) => (
            <SingleThumbnailSelectBox
              selectedOption={value}
              onChangeSelection={(selection) =>
                selection && onChange(selection)
              }
              options={list}
              maxOptionsToDisplay={maxAssetsToDisplay}
              placeholder="Asset"
              searchEnable
              doubleRows
              selectBtnProps={{ width: "49%" }}
              isLoading={!list.length}
            />
          )}
        />
      </Flex>
      {((errors[name] as any | undefined)?.amount ||
        (errors[name] as any | undefined)?.assetId) && (
        <Text color="red.500">
          Please insert a quantity and select an asset
        </Text>
      )}
      {/* -----------------------Add or remove market value button --------------------- */}
      {/* Only show add custom market value button if it is not the out ledger input or if it is a send transaction */}
      {(!name.includes("outLedgers") ||
        transactionType === TransactionType.Send) && (
        <Button
          w={isOpen ? "250px" : "220px"}
          bgColor="transparent"
          _hover={{ bgColor: "transparent" }}
          _active={{ bgColor: "transparent", border: "0" }}
          _focus={{ borderColor: "transparent" }}
          onClick={() => {
            onToggle();
            setValue(`${name}.customMarketValue`, undefined);
          }}
          mb="10px"
        >
          <Text color="red.500" fontWeight="700">
            {isOpen ? "-" : "+"}
          </Text>
          <Text color="red.500" m="0 3px 0 5px" fontSize="0.875rem">
            {isOpen ? "Remove Market Value" : "Add Market Value"}
          </Text>
          <Text color="black.500" fontSize="0.875rem">
            (Optional)
          </Text>
        </Button>
      )}

      {isOpen && (
        <>
          <Flex
            direction="row"
            alignItems="center"
            mb="15px"
            justifyContent="space-between"
          >
            {/* ----------------------- ledger marketValue --------------------- */}
            <FormControl
              isInvalid={
                !!get(errors, `${name}.customMarketValue.marketValue.type`)
              }
              isRequired={isOpen}
            >
              <Controller
                control={control}
                name={`${name}.customMarketValue.marketValue`}
                rules={{
                  validate: (value) =>
                    !value || !amountInvalid({ value, allowZero: true }),
                }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    // NOTE: transactions no longer have the fee ledgers array, the hook form will keep it to maintain a single form for fees and just build the alt in ledgers and alt out ledgers array in the on submit handler
                    isRequired={isOpen}
                    value={value}
                    type="string"
                    placeholder="Market Value"
                    borderColor="#f5f5f5"
                    h="50px"
                    w="100%"
                    _focus={{ boxShadow: "none", borderColor: "#000" }}
                    onChange={(event) => onChange(event.target.value)}
                  />
                )}
              />
            </FormControl>

            {/* ----------------------- ledger marketValueAsset --------------------- */}
            <Controller
              control={control}
              name={`${name}.customMarketValue.assetId`}
              rules={{ required: isOpen }}
              render={({ field: { onChange, value } }) => (
                <SingleThumbnailSelectBox
                  selectedOption={value}
                  onChangeSelection={(selection) => onChange(selection)}
                  options={list}
                  maxOptionsToDisplay={maxAssetsToDisplay}
                  placeholder="Asset"
                  searchEnable
                  doubleRows
                  selectBtnProps={{ width: "100%" }}
                  isLoading={!list.length}
                />
              )}
            />
          </Flex>
          {((errors[name] as any | undefined)?.customMarketValue.marketValue ||
            (errors[name] as any | undefined)?.customMarketValue.assetId) && (
            <>
              <Text color="red.500">
                Please insert a quantity and select an asset
              </Text>
              <Text color="red.500">
                Or click Remove Market Value to disable option
              </Text>
            </>
          )}
        </>
      )}
    </Flex>
  );
};
