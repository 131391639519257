import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Text,
  Image,
  Button,
  Spacer,
  useDisclosure,
  useToast,
  ListItem,
  List,
  ListIcon,
  Link,
  useClipboard,
  FormControl,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Decimal } from "@syla/shared/decimal";
import { throwError } from "@syla/shared/types/helpers/errors";
import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import { LedgerType } from "@syla/shared/types/models/LedgerBase";
import {
  TransactionSourceIdType,
  TransactionType,
} from "@syla/shared/types/models/TransactionBase";
import { getWalletName } from "@syla/shared/types/models/WalletBase";
import {
  NewLedger,
  PatchLedger,
} from "@syla/shared/types/requests/TransactionRequests";
import { sortBy, uniq } from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useEffect,
} from "react";
import {
  useForm,
  useFieldArray,
  FormProvider,
  KeepStateOptions,
  Controller,
} from "react-hook-form";
import { HiChevronDoubleLeft } from "react-icons/hi";
import { PartialObjectDeep } from "type-fest/source/partial-deep";
import { ModalContext } from "../../../../../contexts/ModalContext";

import {
  getAggregatedLedgerTaxOutcomes,
  TaxOutcomeDetail,
} from "../../../../../helper/aggregateLedgerTaxOutcomes";
import {
  buildExplorerUrl,
  showTransactionHashLink,
} from "../../../../../helper/dataSource/buildExplorerUrl";
import { isProduction } from "../../../../../helper/environment";
import { formatDateTime } from "../../../../../helper/formatDateTime";
import { getDirtyValues } from "../../../../../helper/forms/getDirtyValues";
import { adjustAmount } from "../../../../../helper/forms/stringAsNumberInputField";
import {
  BalanceDetail,
  getBalanceDetailsFromLedgers,
} from "../../../../../helper/ledger/aggregateBalanceInLedgers";
import { formatLedgerToPatchLedger } from "../../../../../helper/ledger/formatLedgerToLedgerForm";
import { splitTransactionLedgers } from "../../../../../helper/ledger/splitTransactionLedgers";
import {
  getInLedgerTypeOptionsByTransactionType,
  getOutLedgerTypeOptionsByTransactionType,
  getFeeLedgerTypeOptionsByTransactionType,
} from "../../../../../helper/transaction/getLedgerType";
import { getTradeTypeIconAndTextFromTransaction } from "../../../../../helper/transaction/getTradeTypeIconAndText";
import { useTransactionValues } from "../../../../../hooks/useTransactionValues";
import { useGetWalletOptions } from "../../../../../hooks/wallet/useGetWalletOptions";
import { updateTransaction } from "../../../../../store/actions/updateTransaction";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";
import { useTransactionsStore } from "../../../../../store/transactionsStore";
import { Ledger } from "../../../../../types/ledger";
import {
  EditTransactionForm,
  LedgerForm,
} from "../../../../../types/order/addOrderForm";
import { Transaction } from "../../../../../types/transaction";
import { ButtonVariant } from "../../../../atoms/ButtonVariant";
import { StyledDatePicker } from "../../../../atoms/DatePicker";
import { ImageWithMissingSrc } from "../../../../atoms/ImageWithMissingSrc";
import { InfoToolTip } from "../../../../atoms/InfoToolTip";
import { SkeletonRect } from "../../../../atoms/Skeletons";
import { SingleThumbnailSelectBox } from "../../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";
import { Form } from "../../../../molecules/forms/FormComponents";
import { TransactionsNeedsReviewList } from "../../mainTransactionTable/TransactionsNeedsReviewList";
import { BalanceTableHeaders, BalanceTableRow } from "../BalanceDetails";
import { TaxDetailsHeaders, TaxDetailsRow } from "../TaxDetails";
import { TradeSummaryList } from "../TradeSummaryList";
import { EditModeLedgerTableRow } from "./EditModeLedgerTableRow";
import { renderTransactionNeedsReviewItems } from "./renderTransactionNeedsReviewItems";
import { LedgerTableHeaders } from "./LedgerTableHeaders";
import { SourceDataViewer } from "./SourceDataViewer";
import { StaticLedgerTableRow } from "./StaticLedgerTableRow";

export const TransactionOverview = ({
  transactionId,
  onClickBack,
  setCanDefaultCloseDrawer,
}: {
  transactionId: string;
  onClickBack: () => void;
  setCanDefaultCloseDrawer: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const transactionState = useTransactionsStore(
    (store) => store.transactions?.[transactionId]
  );

  if (!transactionState)
    throwError(new Error(`Transaction ${transactionId} should be set`));

  const transaction = transactionState.data;
  const updating = transactionState.updating;

  const isInvalid = useTransactionsStore((state) => state.refreshing);

  const needsReviewItems = renderTransactionNeedsReviewItems({
    transactions: [transaction],
  });

  // block close while updating
  useEffect(() => {
    setCanDefaultCloseDrawer(!updating);
  }, [setCanDefaultCloseDrawer, updating]);

  const toast = useToast();
  useEffect(() => {
    if (transactionState.error)
      toast({
        title: "Error saving changes",
        description: transactionState.error,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
  }, [toast, transactionState.error]);

  const { icon, text } = getTradeTypeIconAndTextFromTransaction(transaction);
  const { allLedgers } = useTransactionValues([transaction]);

  const ledgers = useMemo(() => getDisplayLedgers(transaction), [transaction]);

  const taxOutcomeDetails: TaxOutcomeDetail[] = useMemo(() => {
    return getAggregatedLedgerTaxOutcomes(transaction.ledgers);
  }, [transaction.ledgers]);
  const balanceDetails: BalanceDetail[] = useMemo(() => {
    return getBalanceDetailsFromLedgers(transaction.ledgers);
  }, [transaction.ledgers]);

  // although it could have multiple transaction hashes, we only support show / edit one now
  const transactionHashSourceId = transaction.sourceIds?.find(
    (sourceId) => sourceId.type === TransactionSourceIdType.TransactionHash
  );
  const transactionHash = transactionHashSourceId?.id;
  const transactionHashDataSourceCode = transactionHashSourceId?.dataSourceCode;

  const { onCopy, hasCopied } = useClipboard(transactionHash ?? "");

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const walletOptions = useGetWalletOptions(accountId)?.data;

  const {
    isOpen: editMode,
    onOpen: onOpenEditMode,
    onClose: onCloseEditMode,
  } = useDisclosure();

  // Use fee types from add transaction  panel

  // ---------------------------------- react hook form ----------------------------------
  const methods = useForm<EditTransactionForm>({
    defaultValues: {
      description: transaction.description,
      transactionHash: transactionHash,
      updateLedgers: ledgers.map(({ ledger }) =>
        formatLedgerToPatchLedger(ledger)
      ),
      newLedgers: [],
      date: transaction.date,
      walletId: transaction.wallet._id,
    },
  });

  // get everything out of the form methods
  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors },
    reset,
    watch,
    control,
    setValue,
  } = methods;

  const {
    fields: newLedgers,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "newLedgers",
  });

  // reset form when transaction is updated
  useEffect(() => {
    console.debug("reset");

    // when editing and updated transaction comes, don't cancel edit mode and override with new values
    const keepModifiedOptions: KeepStateOptions = {
      keepValues: true,
      keepDirty: true,
      keepDirtyValues: true,
      keepErrors: true,
      keepIsValid: true,
    };

    reset(
      {
        description: transaction.description,
        transactionHash: transactionHash,
        updateLedgers: ledgers.map(({ ledger }) =>
          formatLedgerToPatchLedger(ledger)
        ),
        newLedgers: [],
        date: transaction.date,
        walletId: transaction.wallet._id,
      },
      editMode ? keepModifiedOptions : undefined // when not editing, fully reset form
    );
  }, [editMode, ledgers, reset, transaction, transactionHash]);

  const onSubmit = async (data: EditTransactionForm) => {
    // turn off edit mode and early return if form is not dirty or no data
    if (!Object.keys(dirtyFields).length || !data) {
      onCloseEditMode();
      return;
    }

    // keep _id field in any modified ledgers
    dirtyFields.updateLedgers?.forEach((dirtyLedger) => {
      dirtyLedger._id = true;
    });

    const dirtyValues = getDirtyValues(dirtyFields, data);

    if (!isProduction()) console.debug({ dirtyFields, data, dirtyValues });

    const mapParseLedger = (
      ledger: PartialObjectDeep<LedgerForm>
    ): PatchLedger => ({
      ...ledger,
      _id: ledger._id!,
      amount: ledger.amount
        ? Decimal.from(adjustAmount(ledger.amount)).abs().toString()
        : undefined,
      marketValue:
        ledger.marketValue === undefined
          ? undefined
          : adjustAmount(ledger.marketValue),
    });

    const mapNewLedger = (
      ledger: PartialObjectDeep<LedgerForm>
    ): NewLedger => ({
      type: ledger.type!,
      amount: Decimal.from(adjustAmount(ledger.amount)).abs().toString(),
      assetId: ledger.assetId!,
      customMarketValue: ledger.marketValue
        ? {
            assetId: undefined,
            marketValue: adjustAmount(ledger.marketValue),
          }
        : undefined,
    });

    await updateTransaction({
      accountId,
      id: transaction._id,
      updates: {
        description: dirtyValues.description,
        transactionHash: dirtyValues.transactionHash,
        updateLedgers: dirtyValues.updateLedgers
          ? withoutNullable(dirtyValues.updateLedgers).map(mapParseLedger)
          : undefined,
        newLedgers: dirtyValues.newLedgers
          ? withoutNullable(dirtyValues.newLedgers).map(mapNewLedger)
          : undefined,
        date: dirtyValues.date,
        walletId: dirtyValues.walletId,
      },
    });
    onCloseEditMode();
  };
  const { openModal } = useContext(ModalContext);

  const getPatchedTransaction = (
    updateLedgers: LedgerForm[] | undefined,
    newLedgers: LedgerForm[] | undefined
  ): {
    type: TransactionType;
    ledgers: { type: LedgerType }[];
  } => {
    if (!updateLedgers && !newLedgers) {
      return transaction;
    }

    const newAddedLedgers = (newLedgers ?? [])
      .filter((ledger) => !ledger.delete)
      .map((ledger) => ({
        type: ledger.type as LedgerType,
      }));
    const removedExistingLedgerIds = (updateLedgers ?? [])
      .filter((ledger) => ledger.delete)
      .map(({ _id }) => _id);

    const existingLedgers = transaction.ledgers
      .filter((ledger) => !removedExistingLedgerIds.includes(ledger._id))
      .map((ledger) => ({
        type: ledger.type as LedgerType,
      }));

    return {
      type: transaction.type,
      ledgers: existingLedgers.concat(newAddedLedgers),
    };
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit((form) => onSubmit(form))} noValidate>
        <Flex direction="column" bgColor="white.0">
          {/* ------------------------------ Section 1 ------------------------------ */}
          <Flex
            borderWidth="0 0 1px 0"
            alignItems="center"
            p="8px 20px 8px 3px"
          >
            <Button
              mt="5px"
              w="200px"
              isDisabled={updating}
              onClick={onClickBack}
              bgColor="transparent"
              fontWeight="normal"
              _hover={{ bgColor: "transparent" }}
              _active={{ bgColor: "transparent" }}
              justifyContent="flex-start"
            >
              <HiChevronDoubleLeft fontSize="1.5rem" />
            </Button>
            <Spacer />
            <Flex pr="10px">
              <ButtonVariant
                content="Source Data"
                outlineType="outlineRed"
                onClick={() =>
                  openModal({
                    type: "component",
                    size: "3xl",
                    heading: "Source Data",
                    component: (
                      <SourceDataViewer
                        sourceData={transaction.sourceData.map((sd) => sd.data)}
                      />
                    ),
                  })
                }
                h="28px"
                borderWidth="1px !important"
                fontSize="0.75rem"
                fontWeight="normal"
                px="10px"
              />
            </Flex>
            {/* need to define keys for the buttons, otherwise react bugs out and calls the submit function when conditionally rendering the submit button, has to do with how react updates the dom */}
            {editMode ? (
              <Flex>
                {Object.keys(dirtyFields).length > 0 && (
                  <ButtonVariant
                    key="save"
                    color="red"
                    content="Save Changes"
                    onClick={undefined}
                    h="28px"
                    borderWidth="1px !important"
                    fontSize="0.75rem"
                    fontWeight="normal"
                    px="10px"
                    type="submit"
                    isDisabled={updating}
                    isLoading={updating}
                  />
                )}
                <ButtonVariant
                  key="cancel"
                  content="Cancel"
                  onClick={onCloseEditMode}
                  outlineType="outlineRed"
                  h="28px"
                  borderWidth="1px !important"
                  fontSize="0.75rem"
                  fontWeight="normal"
                  px="10px"
                  mx="10px"
                  isDisabled={updating}
                />
              </Flex>
            ) : (
              <ButtonVariant
                key="edit"
                content="Edit"
                rightIcon="edit"
                onClick={onOpenEditMode}
                outlineType="outlineRed"
                h="28px"
                borderWidth="1px !important"
                fontSize="0.75rem"
                fontWeight="normal"
                px="10px"
                isDisabled={updating}
                isLoading={updating}
              />
            )}
          </Flex>

          {/* ------------------------------ Section 2 ------------------------------ */}
          {/* if the transaction type is trade, display the trade icon otherwise display the ledger icon */}
          <Flex direction="column" bgColor="white.0">
            <Flex borderWidth="0 0 1px 0" alignItems="center" p="8px 30px">
              <Flex mr="50px">
                <Image src={icon} alt="" h="24px" mr="5px" />
                <Title>{text}</Title>
              </Flex>
              <TradeSummaryList allLedgers={allLedgers} />
              {/* Removed ability to edit transaction type, could be required in future */}
              {/* {editMode ? (
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <SingleThumbnailSelectBox
                      selectedOption={value}
                      onChangeSelection={(selection) => onChange(selection)}
                      options={getLimitedTransactionTypeOptions}
                      description
                      optionContainerProps={{ width: "370px" }}
                      selectBtnProps={{ height: "42px" }}
                    />
                  )}
                />
              ) : (
                <Flex>
                  <Image
                    src={getTransactionTypeIcon(transaction.type)}
                    alt=""
                    h="24px"
                    mr="5px"
                  />
                  <Title>{transaction.type}</Title>
                </Flex>
              )} */}
            </Flex>
          </Flex>
          {/* ------------------------- Section 3 Center Needs Review Warnings ------------------------- */}
          {needsReviewItems.length > 0 && (
            <Flex direction="column" p="20px" w="100%" minH="100px">
              <Flex justifyContent="space-between" pb="10px">
                <Title>Syla Suggestions</Title>
                <Flex mr="20px">
                  <InfoToolTip
                    label="Syla automatically identifies recommendations for each transaction. By following these suggestions you can improve your tax outcomes and minimise risk."
                    wrap
                    size="20px"
                  />
                </Flex>
              </Flex>
              <TransactionsNeedsReviewList items={needsReviewItems} />
            </Flex>
          )}
          {/* ------------------------------ Section 4 Details ------------------------------ */}
          <Flex direction="column" p="20px" w="100%" minH="100px">
            <Title>Details</Title>
            <Flex direction="row" p="10px 0 10px 0" w="100%">
              <Flex direction="column" p="10px 15px 10px 0" w="50%">
                <Title fontSize="0.9">Location</Title>
                {editMode ? (
                  <FormControl
                    mb="10px"
                    isRequired
                    isDisabled={updating}
                    isInvalid={errors.walletId !== undefined}
                  >
                    <Controller
                      control={control}
                      name="walletId"
                      rules={{ required: "Please select a wallet" }}
                      render={({ field: { onChange, value } }) => (
                        <SingleThumbnailSelectBox
                          selectedOption={value ?? ""}
                          onChangeSelection={(selection) => onChange(selection)}
                          options={walletOptions || []}
                          description
                          searchEnable
                          placeholder="Choose wallet"
                          selectBtnProps={{
                            height: "42px",
                            marginTop: "5px",
                          }}
                        />
                      )}
                    />
                    {errors.walletId && (
                      <FormErrorMessage>
                        {errors.walletId.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                ) : (
                  <Flex pt="5px">
                    <ImageWithMissingSrc
                      src={transaction.dataSource.image}
                      alt=""
                      h="24px"
                      mr="5px"
                    />
                    <Text color="black.550" fontSize="0.875rem">
                      {getWalletName(transaction)}
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Flex direction="column" p="10px 0 10px 0" w="50%">
                <Title fontSize="0.9">Date</Title>
                <Flex pt="5px">
                  {editMode ? (
                    <FormControl
                      mb="10px"
                      isRequired
                      isDisabled={updating}
                      isInvalid={errors.date !== undefined}
                    >
                      <Controller
                        control={control}
                        name="date"
                        rules={{ required: "Please specify date and time" }}
                        render={({ field: { onChange, value } }) => (
                          <StyledDatePicker
                            value={value}
                            onChange={(date) => onChange(date)}
                          />
                        )}
                      />
                      {errors.date && (
                        <FormErrorMessage>
                          {errors.date.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  ) : (
                    <Text color="black.550" fontSize="0.875rem">
                      {formatDateTime(transaction.date, { subSeconds: true })}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column" p="10px 0 20px 0">
              <Title fontSize="0.9">Description</Title>
              {editMode ? (
                <Input
                  isDisabled={updating}
                  placeholder="Type something"
                  defaultValue={transaction.description}
                  {...register("description")}
                />
              ) : (
                <Text fontSize="0.875rem" color="black.550">
                  {transaction.description === ""
                    ? "No description specified"
                    : transaction.description}
                </Text>
              )}
            </Flex>
            {/* ------------------------- Transaction Hash ------------------------- */}
            <Flex direction="column">
              <Title fontSize="0.9">Transaction Hash</Title>
              {editMode ? (
                <Input
                  isDisabled={updating}
                  placeholder="Paste your transaction hash here"
                  defaultValue={transactionHash}
                  {...register("transactionHash")}
                />
              ) : (
                transactionHash && (
                  <Flex direction="row" mb="5px" fontSize="0.875rem">
                    {showTransactionHashLink(transactionHashDataSourceCode) ? (
                      <Link
                        color="#B11B1B"
                        isExternal
                        href={buildExplorerUrl({
                          code: transactionHashDataSourceCode,
                          txHash: transactionHash,
                        })}
                      >
                        {transactionHash} <ExternalLinkIcon mx="2px" />
                      </Link>
                    ) : (
                      <Flex mt="2px">{transactionHash}</Flex>
                    )}
                    <Button
                      fontSize="0.6rem"
                      fontWeight="400"
                      color="black.550"
                      bgColor="white.0"
                      borderColor="black.200"
                      borderWidth="1px"
                      w="40px"
                      h="25px"
                      ml="8px"
                      onClick={onCopy}
                      _hover={{
                        bgColor: "white.500",
                      }}
                    >
                      {hasCopied ? "Copied" : "Copy"}
                    </Button>
                  </Flex>
                )
              )}
            </Flex>
          </Flex>

          {/* ------------------------------ Section 5 Ledgers ------------------------------ */}
          <Flex direction="column" mb="24px">
            <Flex justifyContent="space-between" p="0 20px 20px 20px">
              <Title>Ledger</Title>
              <Flex mr="20px">
                <InfoToolTip
                  label="This transaction resulted in the following changes in balances. Each ledger has a classification which describes the reason why the balance changed."
                  wrap
                  size="20px"
                />
              </Flex>
            </Flex>
            <LedgerTableHeaders editMode={editMode} />
            {ledgers.map(({ ledger, inLedger, feeLedger }, index) => {
              const getLedgerTypes = (inLedger: boolean, feeLedger: boolean) =>
                feeLedger
                  ? getFeeLedgerTypeOptionsByTransactionType(transaction.type)
                  : inLedger
                  ? getInLedgerTypeOptionsByTransactionType(transaction.type)
                  : getOutLedgerTypeOptionsByTransactionType(transaction.type);

              return (
                !watch(`updateLedgers.${index}.delete`) &&
                (editMode ? (
                  <EditModeLedgerTableRow
                    transaction={getPatchedTransaction(
                      watch("updateLedgers"),
                      watch("newLedgers")
                    )}
                    validTypes={getLedgerTypes(inLedger, feeLedger)}
                    name={`updateLedgers.${index}`}
                    key={ledger._id}
                    isDisabled={updating}
                    onDelete={() =>
                      setValue(`updateLedgers.${index}.delete`, true, {
                        shouldDirty: true,
                      })
                    }
                  />
                ) : (
                  <StaticLedgerTableRow ledger={ledger} key={index} />
                ))
              );
            })}
            {editMode && (
              <>
                <Flex ml="15px" mt="10px">
                  <ButtonVariant
                    content="Add"
                    leftIcon="add"
                    spam="auto"
                    ml="10px"
                    outlineType="outlineGray"
                    fontWeight="400"
                    fontSize="0.875rem"
                    _hover={{ bgColor: "white.500" }}
                    _active={{ bgColor: "transparent" }}
                    onClick={() => append({})}
                  />
                </Flex>
                {newLedgers.map((ledger, index) => (
                  <EditModeLedgerTableRow
                    transaction={getPatchedTransaction(
                      watch("updateLedgers"),
                      watch("newLedgers")
                    )}
                    name={`newLedgers.${index}`}
                    validTypes={getFeeLedgerTypeOptionsByTransactionType(
                      transaction.type
                    )}
                    key={ledger.id}
                    isDisabled={updating}
                    onDelete={() => remove(index)}
                  />
                ))}
              </>
            )}
          </Flex>
        </Flex>
        {/* ------------------------------ Section 6 Balances ------------------------------ */}
        <Flex direction="column" mb="24px">
          <Flex justifyContent="space-between" p="0 20px 20px 20px">
            <Title>Balances</Title>
            <Flex mr="20px">
              <InfoToolTip
                label="This transaction resulted in the following final balances in the location where the transaction occured and the following final portfolio balances"
                wrap
                size="20px"
              />
            </Flex>
          </Flex>
          <BalanceTableHeaders />
          {balanceDetails.map((balanceDetail, index) => {
            return (
              <BalanceTableRow balanceDetail={balanceDetail} key={index} />
            );
          })}
        </Flex>
        {/* ------------------------------ Section 7 Tax Details ------------------------------ */}
        <Flex direction="column" mb="24px" pb="20px">
          <Flex justifyContent="space-between" p="0 20px 20px 20px">
            <Title>Tax</Title>
            <Flex mr="20px">
              <InfoToolTip
                label="This transaction resulted in the following tax outcomes"
                wrap
                size="20px"
              />
            </Flex>
          </Flex>
          <TaxDetailsHeaders />
          {taxOutcomeDetails.map((taxOutcomeDetail, index) => (
            <TaxDetailsRow key={index} {...taxOutcomeDetail} />
          ))}
          {taxOutcomeDetails.length === 0 && (
            <Flex justifyContent="center" pt="15px">
              <SkeletonRect isLoaded={!isInvalid}>
                No tax outcome for this transaction
              </SkeletonRect>
            </Flex>
          )}
        </Flex>
      </Form>
    </FormProvider>
  );
};

type LedgerEntry = {
  inLedger: boolean;
  feeLedger: boolean;
  ledger: Ledger;
};

function getDisplayLedgers(transaction: Transaction): LedgerEntry[] {
  const { inLedgers, outLedgers, feeLedgers } = splitTransactionLedgers([
    transaction,
  ]);

  return inLedgers
    .map<LedgerEntry>((ledger) => ({
      ledger,
      inLedger: true,
      feeLedger: false,
    }))
    .concat(
      outLedgers.map<LedgerEntry>((ledger) => ({
        ledger,
        inLedger: false,
        feeLedger: false,
      }))
    )
    .concat(
      feeLedgers.map<LedgerEntry>((ledger) => ({
        ledger,
        inLedger: false,
        feeLedger: true,
      }))
    );
}

const Title = styled(Text)<{ fontSize?: string }>`
  color: #333333;
  font-size: ${(props) => props.fontSize ?? 1}rem;
  font-weight: 400;
`;
