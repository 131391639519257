import { ImportType } from "../models/ImportBase";
import { LedgerType } from "../models/LedgerBase";
import { TransactionType } from "../models/TransactionBase";

export type ImportFilter<TId> =
  | {
      _id: TId;
      type?: never;
    }
  | { _id?: never; type: ImportType };

export type GetGroupsFilter<TId> = {
  transactionType?: TransactionType[];
  ledgerType?: LedgerType[];
  dataSource?: string[];
  asset?: string[];
  status?: StatusOption[];
  datePeriod?: Date[];
  searchQuery?: string;
  imports?: ImportFilter<TId>[];
};

export type GetGroupsRequest<TId> = {
  limit?: number;
  start?: number;
  sort?: GetGroupsRequestSortOption;
} & GetGroupsFilter<TId>;

export enum StatusOption {
  NeedsReview = "NeedsReview",
}

export enum GetGroupsRequestTransactionType {
  Trade = "Trade",
  Receive = "Receive",
  Send = "Send",
  Rebase = "Rebase",
  Orders = "Orders",
}

export enum GetGroupsRequestSortOption {
  DateAscending = "DateAscending",
  DateDescending = "DateDescending",
}
