/* eslint-disable no-fallthrough */
import { Decimal } from "../../decimal";
import { WithModelIds } from "../helpers/WithModelIds";
import { AssetBase } from "./AssetBase";
import { ModelBase } from "./ModelBase";
import {
  TaxOutcomeBase,
  TaxOutcomeType,
  IncomeExpenseTaxOutcomeType,
} from "./TaxOutcomeBase";
import { TransactionType } from "./TransactionBase";

// some types are comment out, when there is use case, could add them back in
export enum LedgerType {
  // receive types
  Acquisition = "Acquisition",
  AcquisitionMarketValue = "Acquisition at Market Value",
  AcquisitionZeroCost = "Acquisition at Zero Cost",
  Airdrop = "Airdrop",
  Borrow = "Borrow",
  CapitalInvested = "Capital Invested",
  Cashback = "Cashback",
  // CfdProfit = "CFD Profit",
  Commission = "Commission",
  Chainsplit = "Chainsplit",
  Deposit = "Deposit",
  DerivativesProfit = "Derivatives Profit",
  Distribution = "Distribution",
  Dust = "Dust",
  FeeRefund = "Fee Refund",
  FuturesFundingRateProfit = "Futures Funding Rate Profit",
  FuturesProfit = "Futures Profit",
  GamblingProfit = "Gambling Profit",
  GiftReceived = "Gift Received",
  LendingInterest = "Lending Interest",
  LendRepaid = "Lend Repaid",
  // InsuranceFundPayout = "Insurance Fund Payout",
  Interest = "Interest Received",
  MiningIncome = "Mining Income",
  Mint = "Mint",
  PaymentReceived = "Payment Received",
  PaymentReversal = "Payment Reversal",
  Promotions = "Promotions",
  Reward = "Reward",
  StakingIncome = "Staking Income",
  TradeRebate = "Trade Rebate",
  TransferIn = "Transfer In",
  RebaseIn = "Rebase In",

  // send types
  BorrowFee = "Borrow Fee",
  BorrowRepaid = "Borrow Repaid",
  BorrowInterestPaid = "Borrow Interest Paid",
  BrokerageFee = "Brokerage Fee",
  CapitalReturned = "Capital Returned",
  // CfdLoss = "CFD Loss",
  DepositFee = "Deposit Fee",
  DerivativesFee = "Derivatives Fee",
  DerivativesLoss = "Derivatives Loss",
  Disposal = "Disposal",
  DisposalMarketValue = "Disposal at Market Value",
  DisposalZeroValue = "Disposal at Zero Value",
  // Donation = "Donation",
  FuturesFee = "Futures Fee",
  FuturesFundingRateLoss = "Futures Funding Rate Loss",
  FuturesLoss = "Futures Loss",
  GamblingLoss = "Gambling Loss",
  GiftSent = "Gift Sent",
  Lend = "Lend",
  LendFee = "Lend Fee",
  LiquidationFee = "Liquidation Fee",
  MintCost = "Mint Cost",
  NetworkFee = "Network Fee",
  OtherFee = "Other Fee",
  OtherLoss = "Other Loss",
  PaymentFee = "Payment Fee",
  PaymentReverted = "Payment Reverted",
  PaymentSent = "Payment Sent",
  // PersonalUseExempt = "Personal Use Exempt",
  ServiceFee = "Service Fee",
  SlashingPenalty = "Slashing Penalty",
  StatementFee = "Statement Fee",
  TransferOut = "Transfer Out",
  RebaseOut = "Rebase Out",
  Unrecoverable = "Unrecoverable",
  Withdrawal = "Withdrawal",
  WithdrawalFee = "Withdrawal Fee",
}

export interface CustomMarketValueBase<TId> {
  asset: AssetBase<TId>;
  value: Decimal;
}

export interface BalancesBase {
  balance: Decimal;
  walletBalance: Decimal;
}

export interface LedgerBase<TId> extends ModelBase<TId> {
  /**
   * A classification of the reason for this ledger entry.
   */
  type: LedgerType;

  /**
   * The asset the Ledger is related to.
   */
  asset: AssetBase<TId>;

  /**
   * The change in balance of the given asset.
   * A positive amount indicates an increase in balance and vice versa for a negative amount.
   */
  amount: Decimal;

  customMarketValue?: CustomMarketValueBase<TId>;

  /* these fields are filled in after transaction import */

  marketValue?: Decimal;
  balances?: BalancesBase;

  taxOutcomes?: TaxOutcomeBase<TId>[];
}

export function isReceiveType({ type }: { type: LedgerType }): boolean {
  switch (type) {
    // receive types
    case LedgerType.Acquisition:
    case LedgerType.AcquisitionMarketValue:
    case LedgerType.AcquisitionZeroCost:
    case LedgerType.Airdrop:
    case LedgerType.Borrow:
    case LedgerType.CapitalInvested:
    case LedgerType.Cashback:
    case LedgerType.Commission:
    case LedgerType.Chainsplit:
    case LedgerType.Deposit:
    case LedgerType.DerivativesProfit:
    case LedgerType.Distribution:
    case LedgerType.Dust:
    case LedgerType.FeeRefund:
    case LedgerType.FuturesFundingRateProfit:
    case LedgerType.FuturesProfit:
    case LedgerType.GamblingProfit:
    case LedgerType.GiftReceived:
    case LedgerType.Interest:
    case LedgerType.LendingInterest:
    case LedgerType.LendRepaid:
    case LedgerType.MiningIncome:
    case LedgerType.Mint:
    case LedgerType.PaymentReceived:
    case LedgerType.PaymentReversal:
    case LedgerType.Promotions:
    case LedgerType.Reward:
    case LedgerType.StakingIncome:
    case LedgerType.TradeRebate:
    case LedgerType.TransferIn:
    case LedgerType.RebaseIn:
      return true;

    // send types
    case LedgerType.BorrowFee:
    case LedgerType.BorrowRepaid:
    case LedgerType.BorrowInterestPaid:
    case LedgerType.BrokerageFee:
    case LedgerType.CapitalReturned:
    case LedgerType.DepositFee:
    case LedgerType.DerivativesFee:
    case LedgerType.DerivativesLoss:
    case LedgerType.Disposal:
    case LedgerType.DisposalMarketValue:
    case LedgerType.DisposalZeroValue:
    case LedgerType.FuturesFee:
    case LedgerType.FuturesFundingRateLoss:
    case LedgerType.FuturesLoss:
    case LedgerType.GamblingLoss:
    case LedgerType.GiftSent:
    case LedgerType.Lend:
    case LedgerType.LendFee:
    case LedgerType.LiquidationFee:
    case LedgerType.MintCost:
    case LedgerType.NetworkFee:
    case LedgerType.OtherFee:
    case LedgerType.OtherLoss:
    case LedgerType.PaymentFee:
    case LedgerType.PaymentReverted:
    case LedgerType.PaymentSent:
    case LedgerType.RebaseOut:
    case LedgerType.ServiceFee:
    case LedgerType.SlashingPenalty:
    case LedgerType.StatementFee:
    case LedgerType.TransferOut:
    case LedgerType.Unrecoverable:
    case LedgerType.Withdrawal:
    case LedgerType.WithdrawalFee:
      return false;
  }
}

export function isFeeLedger({ type }: { type: LedgerType }): boolean {
  switch (type) {
    case LedgerType.BorrowFee:
    case LedgerType.BrokerageFee:
    case LedgerType.DepositFee:
    case LedgerType.DerivativesFee:
    case LedgerType.FuturesFee:
    case LedgerType.LendFee:
    case LedgerType.LiquidationFee:
    case LedgerType.NetworkFee:
    case LedgerType.OtherFee:
    case LedgerType.PaymentFee:
    case LedgerType.ServiceFee:
    case LedgerType.StatementFee:
    case LedgerType.WithdrawalFee:
    case LedgerType.TradeRebate:
    case LedgerType.FuturesFundingRateLoss:
    case LedgerType.FuturesFundingRateProfit:
      return true;

    case LedgerType.Acquisition:
    case LedgerType.AcquisitionMarketValue:
    case LedgerType.AcquisitionZeroCost:
    case LedgerType.Airdrop:
    case LedgerType.Borrow:
    case LedgerType.BorrowRepaid:
    case LedgerType.BorrowInterestPaid:
    case LedgerType.CapitalInvested:
    case LedgerType.Cashback:
    case LedgerType.Chainsplit:
    case LedgerType.Commission:
    case LedgerType.Deposit:
    case LedgerType.DerivativesLoss:
    case LedgerType.DerivativesProfit:
    case LedgerType.Distribution:
    case LedgerType.Dust:
    case LedgerType.FeeRefund:
    case LedgerType.FuturesLoss:
    case LedgerType.FuturesProfit:
    case LedgerType.GamblingProfit:
    case LedgerType.GiftReceived:
    case LedgerType.Lend:
    case LedgerType.LendingInterest:
    case LedgerType.LendRepaid:
    case LedgerType.Interest:
    case LedgerType.MiningIncome:
    case LedgerType.Mint:
    case LedgerType.MintCost:
    case LedgerType.PaymentReceived:
    case LedgerType.PaymentReversal:
    case LedgerType.PaymentReverted:
    case LedgerType.Promotions:
    case LedgerType.StakingIncome:
    case LedgerType.SlashingPenalty:
    case LedgerType.RebaseIn:
    case LedgerType.Reward:
    case LedgerType.CapitalReturned:
    case LedgerType.Disposal:
    case LedgerType.DisposalMarketValue:
    case LedgerType.DisposalZeroValue:
    case LedgerType.GamblingLoss:
    case LedgerType.GiftSent:
    case LedgerType.OtherLoss:
    case LedgerType.PaymentSent:
    case LedgerType.RebaseOut:
    case LedgerType.TransferIn:
    case LedgerType.TransferOut:
    case LedgerType.Unrecoverable:
    case LedgerType.Withdrawal:
      return false;
  }
}

export function canSetCustomMarketValue<
  TId extends string | object = string | object
>(
  {
    type: transactionType,
    ledgers,
  }: {
    type: TransactionType;
    ledgers: WithModelIds<Pick<LedgerBase<TId>, "type">>[];
  },
  { type: ledgerType }: Pick<LedgerBase<TId>, "type">
): boolean {
  switch (transactionType) {
    case TransactionType.Trade:
    case TransactionType.Wrap: {
      const acquisitions = ledgers.filter(
        (l) => l.type == LedgerType.Acquisition
      );
      const disposals = ledgers.filter((l) => l.type == LedgerType.Disposal);
      // one is inferred from the other
      if (acquisitions.length == 1 && disposals.length == 1)
        return ledgerType !== LedgerType.Acquisition;
      // acquisition is inferred from Disposals
      else if (acquisitions.length == 1) {
        return ledgerType !== LedgerType.Acquisition;
        // disposal is inferred from Acquisitions
      } else if (disposals.length == 1) {
        return ledgerType !== LedgerType.Disposal;
      }

      // for multi trades no inference happens
      return true;
    }
    case TransactionType.Mint: {
      // simpler to always infer from mint cost
      return ledgerType != LedgerType.Mint;
    }
    case TransactionType.LiquidityIn:
      return ledgerType !== LedgerType.Acquisition;
    case TransactionType.LiquidityOut:
      return ledgerType !== LedgerType.Disposal;
    // todo: exclude transactions with user's domestic currency as one of the parts
    //  !ledgers.find(
    //           (ledger) =>
    //             ledger.asset._id.toString() == userDomesticCurrency.toString()
    case TransactionType.Rebase:
      return ledgerType !== LedgerType.RebaseIn;
    case TransactionType.Receive:
    case TransactionType.Send:
      return true;
  }
}

export function canLedgerBeRemovedInTransaction<
  TId extends string | object = string | object
>(
  {
    type: transactionType,
    ledgers,
  }: // ledgers,
  {
    type: TransactionType;
    ledgers: Pick<LedgerBase<TId>, "type">[];
  },
  { type: ledgerType }: Pick<LedgerBase<TId>, "type">
): boolean {
  const acquisitionLedgerCount = ledgers.filter(
    (l) => l.type == LedgerType.Acquisition
  ).length;
  const disposalLedgerCount = ledgers.filter(
    (l) => l.type == LedgerType.Disposal
  ).length;
  const receiveLedgerCount = ledgers.filter((l) => isReceiveType(l)).length;
  const sendLedgerCount = ledgers.filter((l) => !isReceiveType(l)).length;

  switch (transactionType) {
    case TransactionType.Trade:
    case TransactionType.Wrap:
      if (ledgerType == LedgerType.Acquisition && acquisitionLedgerCount == 1)
        return false;
      if (ledgerType == LedgerType.Disposal && disposalLedgerCount == 1)
        return false;
      break;
    case TransactionType.LiquidityIn:
      if (ledgerType == LedgerType.Acquisition && acquisitionLedgerCount == 1)
        return false;
      if (ledgerType == LedgerType.Disposal && disposalLedgerCount == 2)
        return false;
      break;
    case TransactionType.LiquidityOut:
      if (ledgerType == LedgerType.Acquisition && acquisitionLedgerCount == 2)
        return false;
      if (ledgerType == LedgerType.Disposal && disposalLedgerCount == 1)
        return false;
      break;
    case TransactionType.Rebase:
      if (
        ledgerType == LedgerType.RebaseIn ||
        ledgerType == LedgerType.RebaseOut
      )
        return false;
      break;
    case TransactionType.Receive:
      if (isReceiveType({ type: ledgerType }) && receiveLedgerCount == 1)
        return false;
      break;
    case TransactionType.Send:
      if (!isReceiveType({ type: ledgerType }) && sendLedgerCount == 1)
        return false;
      break;
  }
  return true;
}

export const GetValidTransactionTypesForLedgerType = (
  type: LedgerType
): TransactionType[] => {
  switch (type) {
    case LedgerType.Acquisition:
      return [
        TransactionType.Trade,
        TransactionType.Wrap,
        TransactionType.LiquidityIn,
        TransactionType.LiquidityOut,
        TransactionType.Receive,
      ];
    case LedgerType.AcquisitionMarketValue:
      return [TransactionType.Receive];
    case LedgerType.AcquisitionZeroCost:
      return [TransactionType.Receive];
    case LedgerType.Airdrop:
      return [TransactionType.Receive];
    case LedgerType.Borrow:
      return [TransactionType.Receive];
    case LedgerType.BorrowFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.BorrowRepaid:
      return [TransactionType.Send];
    case LedgerType.BorrowInterestPaid:
      return [TransactionType.Send];
    case LedgerType.BrokerageFee:
      return [
        TransactionType.Trade,
        TransactionType.Send, // for missing data on disposal at zero value
        TransactionType.Receive, // for missing data on acquisition at zero cost
        TransactionType.Wrap,
      ];
    case LedgerType.CapitalInvested:
      return [TransactionType.Receive];
    case LedgerType.CapitalReturned:
      return [TransactionType.Send];
    case LedgerType.Cashback:
      return [TransactionType.Receive];
    case LedgerType.Commission:
      return [TransactionType.Receive];
    case LedgerType.Chainsplit:
      return [TransactionType.Receive];
    case LedgerType.Deposit:
      return [TransactionType.Receive];
    case LedgerType.DepositFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.DerivativesFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.DerivativesLoss:
      return [TransactionType.Send];
    case LedgerType.DerivativesProfit:
      return [TransactionType.Receive];
    case LedgerType.Disposal:
      return [
        TransactionType.Trade,
        TransactionType.Wrap,
        TransactionType.LiquidityIn,
        TransactionType.LiquidityOut,
        TransactionType.Send,
      ];
    case LedgerType.DisposalMarketValue:
      return [TransactionType.Send];
    case LedgerType.DisposalZeroValue:
      return [TransactionType.Send];
    case LedgerType.Distribution:
      return [TransactionType.Receive];
    case LedgerType.Dust:
      return [TransactionType.Receive];
    case LedgerType.FeeRefund:
      return [TransactionType.Receive];
    case LedgerType.FuturesFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.FuturesFundingRateLoss:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.FuturesFundingRateProfit:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.FuturesLoss:
      return [TransactionType.Send];
    case LedgerType.FuturesProfit:
      return [TransactionType.Receive];
    case LedgerType.GamblingLoss:
      return [TransactionType.Send];
    case LedgerType.GamblingProfit:
      return [TransactionType.Receive];
    case LedgerType.GiftReceived:
      return [TransactionType.Receive];
    case LedgerType.GiftSent:
      return [TransactionType.Send];
    case LedgerType.LiquidationFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.Lend:
      return [TransactionType.Send];
    case LedgerType.LendFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.LendingInterest:
      return [TransactionType.Receive];
    case LedgerType.LendRepaid:
      return [TransactionType.Receive];
    case LedgerType.Interest:
      return [TransactionType.Receive];
    case LedgerType.MiningIncome:
      return [TransactionType.Receive];
    case LedgerType.Mint:
      return [TransactionType.Mint, TransactionType.Receive];
    case LedgerType.MintCost:
      return [TransactionType.Mint, TransactionType.Send];
    case LedgerType.NetworkFee:
      return [
        TransactionType.Send,
        TransactionType.Receive,
        TransactionType.Trade,
        TransactionType.Mint,
        TransactionType.Rebase,
        TransactionType.Wrap,
        TransactionType.LiquidityIn,
        TransactionType.LiquidityOut,
      ];
    case LedgerType.OtherFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.OtherLoss:
      return [TransactionType.Send];
    case LedgerType.PaymentFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.PaymentReceived:
      return [TransactionType.Receive];
    case LedgerType.PaymentReversal:
      return [TransactionType.Receive];
    case LedgerType.PaymentReverted:
      return [TransactionType.Send];
    case LedgerType.Promotions:
      return [TransactionType.Receive];
    case LedgerType.PaymentSent:
      return [TransactionType.Send];
    case LedgerType.ServiceFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.SlashingPenalty:
      return [TransactionType.Send];
    case LedgerType.StakingIncome:
      return [TransactionType.Receive];
    case LedgerType.StatementFee:
      return [TransactionType.Send, TransactionType.Receive];
    case LedgerType.TradeRebate:
      return [
        TransactionType.Trade,
        TransactionType.Receive,
        TransactionType.Send,
      ];
    case LedgerType.TransferIn:
      return [TransactionType.Receive];
    case LedgerType.TransferOut:
      return [TransactionType.Send];
    case LedgerType.RebaseIn:
      return [TransactionType.Rebase];
    case LedgerType.RebaseOut:
      return [TransactionType.Rebase];
    case LedgerType.Reward:
      return [TransactionType.Receive];
    case LedgerType.Unrecoverable:
      return [TransactionType.Send];
    case LedgerType.Withdrawal:
      return [TransactionType.Send];
    case LedgerType.WithdrawalFee:
      return [TransactionType.Send, TransactionType.Receive];
  }
};

/** Convenience cached lookup for valid Ledger types organised by TransactionType */
export const validLedgerTypesByTransactionType = <
  Record<TransactionType, LedgerType[]>
>Object.fromEntries(
  Object.values(TransactionType).map((transactionType: TransactionType) => [
    transactionType,
    Object.values(LedgerType).filter((ledgerType) =>
      GetValidTransactionTypesForLedgerType(ledgerType).includes(
        transactionType
      )
    ),
  ])
);

export function getLedgerTaxValuationType(ledger: {
  type: LedgerType;
}): "Zero" | "MarketValue" | "CostBase" | "RolloverCostBase" {
  switch (ledger.type) {
    // apply default valuation type to unclassified Withdrawal/Deposit
    case LedgerType.Withdrawal:
    case LedgerType.Deposit:
      return "MarketValue";

    // receive types
    case LedgerType.AcquisitionZeroCost:
    case LedgerType.Chainsplit:
    case LedgerType.TransferIn:
      return "Zero";
    case LedgerType.RebaseIn:
      return "RolloverCostBase"; // not really a valid state, this method should not be called with this ledger type.
    case LedgerType.Airdrop:
    case LedgerType.Acquisition:
    case LedgerType.AcquisitionMarketValue:
    case LedgerType.Borrow:
    case LedgerType.CapitalInvested:
    case LedgerType.Cashback:
    // case LedgerType.CfdProfit:
    case LedgerType.Commission:
    case LedgerType.DerivativesProfit:
    case LedgerType.Distribution:
    case LedgerType.Dust:
    case LedgerType.FeeRefund:
    case LedgerType.FuturesFundingRateProfit:
    case LedgerType.FuturesProfit:
    case LedgerType.GamblingProfit:
    case LedgerType.GiftReceived:
    // case LedgerType.InsuranceFundPayout:
    case LedgerType.Interest:
    case LedgerType.LendingInterest:
    case LedgerType.LendRepaid:
    case LedgerType.MiningIncome:
    case LedgerType.Mint:
    case LedgerType.PaymentReceived:
    case LedgerType.PaymentReversal:
    case LedgerType.Promotions:
    case LedgerType.Reward:
    case LedgerType.StakingIncome:
    case LedgerType.TradeRebate:
      return "MarketValue";

    // send types
    case LedgerType.RebaseOut:
      return "RolloverCostBase";
    case LedgerType.DisposalZeroValue:
    case LedgerType.Unrecoverable:
    case LedgerType.TransferOut:
      return "Zero";
    case LedgerType.CapitalReturned:
    case LedgerType.PaymentReverted:
    case LedgerType.PaymentSent:
    case LedgerType.Disposal:
    case LedgerType.DisposalMarketValue:
    case LedgerType.GiftSent:
    // case LedgerType.Donation:
    // case LedgerType.PersonalUseExempt:
    case LedgerType.BorrowRepaid:
    case LedgerType.BorrowInterestPaid:
    case LedgerType.Lend:
    case LedgerType.OtherLoss:
    case LedgerType.FuturesFee:
    case LedgerType.FuturesLoss:
    case LedgerType.FuturesFundingRateLoss:
    case LedgerType.DerivativesLoss:
    // case LedgerType.CfdLoss:
    case LedgerType.GamblingLoss:
    case LedgerType.LendFee:
    case LedgerType.LiquidationFee:
    // case LedgerType.LendingLoss:
    case LedgerType.MintCost:
    case LedgerType.OtherFee:
    case LedgerType.DepositFee:
    case LedgerType.DerivativesFee:
    case LedgerType.WithdrawalFee:
    case LedgerType.ServiceFee:
    case LedgerType.SlashingPenalty:
    case LedgerType.StatementFee:
    case LedgerType.NetworkFee:
    case LedgerType.PaymentFee:
    case LedgerType.BorrowFee:
    case LedgerType.BrokerageFee:
      return "MarketValue";
  }
}

export function getNonCapitalTaxOutcomeType(
  ledgerType: LedgerType
): IncomeExpenseTaxOutcomeType | "Ignore" {
  switch (ledgerType) {
    // these don't produce income
    case LedgerType.Deposit:
    case LedgerType.Withdrawal:
    case LedgerType.RebaseIn:
    case LedgerType.RebaseOut:
    case LedgerType.TransferIn:
    case LedgerType.TransferOut:
      return "Ignore";

    // receive types
    case LedgerType.AcquisitionZeroCost:
    case LedgerType.AcquisitionMarketValue:
    case LedgerType.Chainsplit:
    case LedgerType.Borrow:
    case LedgerType.GiftReceived:
    case LedgerType.Acquisition:
    case LedgerType.PaymentReversal:
    case LedgerType.CapitalInvested:
    case LedgerType.LendRepaid:
    case LedgerType.Mint:
      return "Ignore";

    case LedgerType.GamblingProfit:
      return TaxOutcomeType.NonAssessableIncome;

    case LedgerType.Airdrop:
    case LedgerType.Cashback:
    case LedgerType.Distribution:
    case LedgerType.DerivativesProfit:
    case LedgerType.Dust:
    // case LedgerType.CfdProfit:
    case LedgerType.Commission:
    case LedgerType.FeeRefund:
    case LedgerType.FuturesFundingRateProfit:
    case LedgerType.FuturesProfit:
    // case LedgerType.InsuranceFundPayout:
    case LedgerType.Interest:
    case LedgerType.LendingInterest:
    case LedgerType.MiningIncome:
    case LedgerType.PaymentReceived:
    case LedgerType.Promotions:
    case LedgerType.StakingIncome:
    case LedgerType.TradeRebate:
    case LedgerType.Reward:
      return TaxOutcomeType.OtherIncome;

    // send types
    case LedgerType.CapitalReturned:
    // case LedgerType.PersonalUseExempt:
    case LedgerType.Unrecoverable:
    case LedgerType.DisposalZeroValue:
    case LedgerType.BorrowRepaid:
    case LedgerType.GiftSent:
    // case LedgerType.Donation:
    case LedgerType.Disposal:
    case LedgerType.DisposalMarketValue:
    case LedgerType.PaymentSent:
    case LedgerType.Lend:
    case LedgerType.MintCost:
      return "Ignore";

    case LedgerType.GamblingLoss:
      return TaxOutcomeType.NonDeductibleExpense;

    case LedgerType.OtherFee:
    case LedgerType.BorrowFee:
    case LedgerType.BorrowInterestPaid:
    case LedgerType.FuturesFee:
    case LedgerType.LendFee:
    case LedgerType.LiquidationFee:
    case LedgerType.DepositFee:
    case LedgerType.DerivativesFee:
    case LedgerType.WithdrawalFee:
    case LedgerType.ServiceFee:
    case LedgerType.SlashingPenalty:
    case LedgerType.StatementFee:
    case LedgerType.NetworkFee:
    case LedgerType.PaymentFee:
    case LedgerType.PaymentReverted:
    case LedgerType.BrokerageFee:
    case LedgerType.DerivativesLoss:
    case LedgerType.FuturesLoss:
    case LedgerType.FuturesFundingRateLoss:
    // case LedgerType.CfdLoss:
    // case LedgerType.LendingLoss:
    case LedgerType.OtherLoss:
      return TaxOutcomeType.OtherExpense;
  }
}

export function isTransfer(ledger: Pick<LedgerBase<any>, "type">) {
  return [LedgerType.TransferIn, LedgerType.TransferOut].includes(ledger.type);
}
