import {
  Checkbox,
  Flex,
  Image,
  Text,
  FlexProps,
  HStack,
  TextProps,
  BoxProps,
  VStack,
} from "@chakra-ui/react";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { throwError } from "@syla/shared/types/helpers/errors";
import {
  includeNeedsReviewItem,
  TransactionSourceIdType,
} from "@syla/shared/types/models/TransactionBase";
import { uniq } from "lodash";
import React, { memo, useMemo } from "react";
import { formatTime, formatDate } from "../../../../helper/formatDateTime";
import { BalanceDetail } from "../../../../helper/ledger/aggregateBalanceInLedgers";
import { NumberStyler } from "../../../../helper/NumberStyler";
import { getTradeTypeIconAndTextFromOrder } from "../../../../helper/transaction/getTradeTypeIconAndText";
import { useTransactionValues } from "../../../../hooks/useTransactionValues";
import { useTransactionsStore } from "../../../../store/transactionsStore";
import { OrderFilterSetting } from "../../../../types/order/orderQuery";
import { SkeletonRect } from "../../../atoms/Skeletons";
import { SliceWords } from "../SliceWords";
import { RenderAssetsIcon } from "../transactionDrawer/RenderAssetsIcon";
import { AddressDisplay } from "./AddressDisplay";
import {
  mainTransactionTableStyleProps,
  CheckboxColumn,
} from "./MainTransactionsTable";
import { OrderNeedsReviewSummary } from "./OrderNeedsReviewSummary";
import { useGetTxHint } from "./useGetTxHint";

interface IMainTransactionTableRow {
  groupId: string;
  onSelect: (id: string) => void;
  isSelected: boolean;
  onClickOrder: (id: string) => void;
  filters: OrderFilterSetting;
}

export const MainTransactionTableRow = memo(
  ({
    groupId,
    onSelect,
    isSelected,
    onClickOrder,
    filters,
  }: IMainTransactionTableRow): JSX.Element => {
    // useEffect(() => console.debug("MainTransactionTableRow render"));

    const orderResponse =
      useTransactionsStore((state) => state.groups?.[groupId]?.data) ??
      throwError(new Error("Group should be set"));

    const { negativeChanges, positiveChanges, outSources, inSources } =
      useTransactionValues(orderResponse.transactions);

    const showNeedsReviewIndicator = orderResponse.transactions.some(
      (transaction) =>
        transaction.needsReview?.filter(includeNeedsReviewItem).length
    );

    const { icon, text } = getTradeTypeIconAndTextFromOrder(orderResponse);

    const hint = useGetTxHint(orderResponse);

    const fromAddresses = useMemo(
      () =>
        uniq(
          orderResponse.transactions.flatMap(
            (t) =>
              t.sourceIds
                ?.filter(
                  (s) => s.type == TransactionSourceIdType.ReceivedFromAddress
                )
                .map((s) => s.id) ?? []
          )
        ),
      [orderResponse.transactions]
    );

    const toAddresses = useMemo(
      () =>
        uniq(
          orderResponse.transactions.flatMap(
            (t) =>
              t.sourceIds
                ?.filter((s) => s.type == TransactionSourceIdType.SentToAddress)
                .map((s) => s.id) ?? []
          )
        ),
      [orderResponse.transactions]
    );

    return (
      <Flex
        bgColor={isSelected ? "red.50" : "white.0"}
        borderWidth="0 0 1px 0"
        px="20px"
        py={2}
        align="center"
        {...mainTransactionTableStyleProps.Row}
      >
        {/* ------------------- Checkbox ------------------- */}
        <CheckboxColumn>
          <Checkbox
            onChange={() => onSelect(orderResponse.order._id.toString())}
            isChecked={isSelected}
            colorScheme="red"
          />
        </CheckboxColumn>
        {/* ------------------- Body ------------------- */}
        <Flex
          direction="row"
          cursor="pointer"
          alignItems="center"
          p="10px 0"
          borderRadius="0"
          bgColor="transparent"
          h="70px"
          onClick={() => onClickOrder(groupId)}
          w="100%"
        >
          {/* ------------------- Type ------------------- */}
          <Flex direction="row" alignItems="center" minW="100px" w="15%">
            {/* if the order has multiple transactions, display the order icon, else if the transaction type is trade, display the trade icon otherwise display the ledger icon */}
            <Image src={icon} alt="" mr="5px" maxH="100%" />
            <VStack align="flex-start" spacing={1}>
              <Text
                textTransform="capitalize"
                fontWeight="500"
                color="black.600"
                fontSize="0.875rem"
              >
                {text}
              </Text>
              {!!hint && hint != text && (
                <SecondaryText noOfLines={2}>{hint}</SecondaryText>
              )}
            </VStack>
          </Flex>
          {/* ------------------- Date ------------------- */}
          <Flex direction="column" minW="180px" w="10%">
            <HStack gap={2} spacing={0} wrap="wrap">
              <Text
                textAlign="left"
                fontSize="0.875rem"
                color="black.600"
                fontWeight="500"
              >
                {formatDate(orderResponse.order.date)}
              </Text>
              <SecondaryText textAlign="left" fontSize="0.875rem">
                {formatTime(orderResponse.order.date)}
              </SecondaryText>
            </HStack>

            <Text
              textAlign="left"
              fontSize="0.625rem"
              color="black.600"
              fontWeight="500"
              display={orderResponse.transactions.length > 1 ? "block" : "none"}
            >{`${orderResponse.transactions.length} sub-transactions`}</Text>
          </Flex>
          {/* ------------------- Out ------------------- */}
          <InOutCell
            direction="out"
            changes={negativeChanges}
            dataSources={negativeChanges.length ? outSources : []}
            addresses={fromAddresses}
          />
          {/* ------------------- In ------------------- */}
          <InOutCell
            direction="in"
            changes={positiveChanges}
            dataSources={positiveChanges.length ? inSources : []}
            addresses={toAddresses}
          />
          {/* ------------------- Running Balance ------------------- */}
          {filters.asset.length == 1 && (
            <Flex
              minW="150px"
              w="15%"
              mr="50px"
              bgColor={isSelected ? "red.50" : "white.0"}
            >
              {orderResponse && (
                <SkeletonRect isLoaded={true}>
                  <NumberStyler
                    num={
                      orderResponse.transactions
                        .flatMap((t) => t.ledgers)
                        .filter((l) => l.asset._id == filters.asset[0])
                        .at(-1)?.balances?.[
                        filters.dataSource.length == 1
                          ? "walletBalance"
                          : "balance"
                      ]
                    }
                    negativeColored
                    fontSize="0.75rem"
                    fontWeight="500"
                  />
                </SkeletonRect>
              )}
            </Flex>
          )}
          {/* ------------------- Gain ------------------- */}
          <Flex
            minW="150px"
            w="15%"
            mr="50px"
            bgColor={isSelected ? "red.50" : "white.0"}
            justifyContent="flex-end"
          >
            {/* We display no value for an undefined tax outcome, in contrast to a
          zero value for tax outcomes that sum to zero.*/}
            {orderResponse.taxOutcome && (
              <SkeletonRect isLoaded={true}>
                <NumberStyler
                  num={orderResponse.taxOutcome}
                  colored
                  bgColored
                  unit="currency"
                  fontSize="0.75rem"
                  fontWeight="500"
                  greenZeroValue
                />
              </SkeletonRect>
            )}
          </Flex>
        </Flex>
        {/* Needs Review Indicator */}
        <Flex {...mainTransactionTableStyleProps.NeedsReviewColumn}>
          {showNeedsReviewIndicator && (
            <OrderNeedsReviewSummary order={orderResponse} />
          )}
        </Flex>
        {/* ------------------- 3 dots menu ------------------- */}
        {/*<Flex w="50px" h="100%" bgColor="transparent">*/}
        {/*  <TransactionRowMenu isChecked={isSelected} />*/}
        {/*</Flex>*/}
      </Flex>
    );
  }
);

const inOutColStyleProps: FlexProps = {
  overflow: "hidden",
  pr: "20px",
};

export const RegularText = (props: TextProps) => (
  <Text color="black.700" fontSize="0.75rem" {...props} />
);

export const SecondaryText = (props: TextProps) => (
  <Text color="black.500" fontSize="0.75rem" {...props} />
);

const prominentTextProps: BoxProps = {
  fontWeight: "600",
};

const InOutContainer = (props: FlexProps) => (
  <Flex minW="300px" w="30%" h="100%" align="flex-start" {...props} />
);

const InOutCell = ({
  changes,
  dataSources,
  addresses,
  direction,
}: {
  changes: BalanceDetail[];
  dataSources: string[];
  addresses: string[];
  direction: "in" | "out";
}) => (
  <InOutContainer>
    <RenderAssetsIcon icons={changes.map((nc) => nc.asset.image)} />
    <Flex direction="column" {...inOutColStyleProps}>
      <Flex alignItems="center">
        <SliceWords dataSources={dataSources} />
      </Flex>
      {!!addresses.length && <AddressDisplay addresses={addresses} />}
      {!!changes.length && (
        <Flex>
          <Text
            color={direction == "in" ? "green.500" : "red.500"}
            fontSize="0.75rem"
            {...prominentTextProps}
          >
            {direction == "in" ? "+" : "-"}
          </Text>
          <NumberStyler
            num={changes[0].amount}
            mx="5px"
            color="black.700"
            fontSize="0.75rem"
            assetInfo={changes[0]?.asset}
            signed={false}
            smartRound
            maxLength={12}
            {...prominentTextProps}
          />
          <RegularText noOfLines={1} {...prominentTextProps}>
            {getAssetShortName(changes[0].asset)}
          </RegularText>
          {changes.length > 1 && (
            <SecondaryText fontSize="0.75rem" pl="5px">
              + more
            </SecondaryText>
          )}
        </Flex>
      )}
    </Flex>
  </InOutContainer>
);
