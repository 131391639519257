import { sumWithDecimal, Decimal } from "@syla/shared/decimal";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { LedgerType, isTransfer } from "@syla/shared/types/models/LedgerBase";
import { isCgtTaxOutcomeType } from "@syla/shared/types/models/TaxOutcomeBase";
import { NeedsReviewType } from "@syla/shared/types/models/TransactionBase";
import { sortBy, uniq } from "lodash";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Transaction } from "../../../../../types/transaction";

export function renderTransactionNeedsReviewItems({
  transactions,
}: {
  transactions: Transaction[];
}) {
  const ledgers = transactions.flatMap((t) => t.ledgers);

  const needsReviewItems = sortBy(
    // consistent sort order
    uniq(transactions.flatMap((t) => t.needsReview?.map((nr) => nr.type) ?? []))
  ).flatMap((nrType) => renderNeedsReviewItems(ledgers, nrType));

  return needsReviewItems;
}

function renderNeedsReviewItems(
  ledgers: Transaction["ledgers"],
  needsReviewType: NeedsReviewType
): JSX.Element[] {
  let messages: string[];

  switch (needsReviewType) {
    case NeedsReviewType.UnmatchedTransfer:
      {
        messages = ledgers
          .filter(isTransfer)
          .map(
            (ledger) =>
              `There is a missing corresponding **${
                ledger.type == LedgerType.TransferIn
                  ? LedgerType.TransferOut
                  : LedgerType.TransferIn
              }** of **${formatLedgerAmount(
                ledger.amount.negated()
              )} ${getAssetShortName(ledger.asset)}**`
          );
      }
      break;
    case NeedsReviewType.MissingAcquisitionParcel:
      {
        const ledgersUnmatchedAmount = ledgers
          .map((ledger) => {
            const unmatchedOutcomes =
              ledger.taxOutcomes?.filter(
                // no sourceLedger means an unmatched acquisition
                (taxOutcome) =>
                  isCgtTaxOutcomeType(taxOutcome) && !taxOutcome.sourceLedger
              ) ?? [];

            return {
              ...ledger,
              unmatchedAmount: sumWithDecimal(unmatchedOutcomes, "amount"),
              unmatchedValue: sumWithDecimal(unmatchedOutcomes, "proceeds"),
            };
          })
          .filter((ledgerUnmatched) => ledgerUnmatched.unmatchedAmount.gt("0"));

        // console.log({ ledgersUnmatchedAmount });

        messages = ledgersUnmatchedAmount.map((ledgerUnmatched) => {
          // const ledgerType = ledgerUnmatched.type;
          // const ledgerAmount = ledgerUnmatched.amount.abs();
          const ledgerAsset = getAssetShortName(ledgerUnmatched.asset);
          const unmatchedAmount = formatLedgerAmount(
            ledgerUnmatched.unmatchedAmount
          );

          const marketValue = ledgerUnmatched.marketValue;
          const unmatchedValue = ledgerUnmatched.unmatchedValue.format({
            fixedPointDigits: 2,
            currencyCode: "AUD",
            separateThousands: true,
          }).text;

          return (
            `Your transactions are missing an acquisition of **${unmatchedAmount} ${ledgerAsset}**` +
            (marketValue ? ` worth **${unmatchedValue} AUD**` : "")
          );
        });
      }
      break;
    case NeedsReviewType.MissingMarketValue:
      messages = ledgers
        .filter((l) => !l.marketValue)
        .map((l) => `Add a market value for the **${l.type}**`);
      break;
    case NeedsReviewType.MissingAssetCode:
      messages = ledgers
        .filter((l) => getAssetShortName(l.asset) == "<unknown>")
        .map(
          (l) => `Asset is missing. Change **${l.type}** to the correct asset.`
        );
      break;
    case NeedsReviewType.UnclassifiedLedgers:
      messages = ledgers
        .filter((l) =>
          [LedgerType.Deposit, LedgerType.Withdrawal].includes(l.type)
        )
        .map((l) => `Classify the **${l.type}** to something more specific`);
      break;
    // disable these for now since user can do nothing about them
    case NeedsReviewType.WithdrawalInclusiveOfFee:
    case NeedsReviewType.MissingData:
      messages = [];
      break;
  }

  return messages.map((message, index) => (
    <ReactMarkdown
      // prevent ReactMarkdown from rendering the text in a <p> tag
      components={{
        p: React.Fragment,
      }}
      key={index}
    >
      {message}
    </ReactMarkdown>
  ));
}

function formatLedgerAmount(amount: Decimal) {
  return amount.format({
    separateThousands: true,
  }).text;
}
